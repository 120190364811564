.form-single {
  &.apartment-selection {
    padding-top: 0;

    &::before {
      content: none;
    }
  }

  .form__container {
    padding: 32px 70px;
    @media (max-width: 1140px){
      padding: 32px 40px;
    }
    @media (max-width: 768px) {
      padding: 25px 30px;
    }
    @media (max-width: 520px) {
      padding: 20px 13px 15px 20px;
    }
  }

  &__title {
    text-align: center;
    color: #FFF;
    font-size: 42px;
    font-weight: 500;
    line-height: 1.15;
    margin-bottom: 30px;
    @media (max-width: 520px) {
      margin-bottom: 23px;
    }

    h2 {
      @media (max-width: 1600px){
        font-size: 38px;
      }
      @media (max-width: 1280px){
        font-size: 30px !important;
        line-height: 1.12 !important;
      }
      @media (max-width: 1050px) {
        font-size: 28px !important;
        font-weight: 300;
      }
      @media (max-width: 768px) {
        font-size: 26px !important;
        text-align: center !important;
        padding-top: 68px !important;
      }
      @media (max-width: 520px) {
        font-size: 22px !important;
        text-align: right !important;
        text-transform: lowercase !important;
      }
    }

    span {
      @media (min-width: 1050px) {
        display: flex;
        gap: 14px;
        text-align: center;
        justify-content: center;
      }
      @media (max-width: 768px) {
        color: #fff !important;
        font-size: 26px !important;
        font-weight: 300 !important;
        text-transform: lowercase;
      }
      @media (max-width: 520px) {
        font-size: 22px !important;
      }
    }

    b {
      font-weight: 500;
      display: block;
      @media (max-width: 1050px) {
        color: #E0BE74;
        line-height: 1;
        font-size: 36px;
      }
      @media (max-width: 768px) {
        text-transform: uppercase;
      }
      @media (max-width: 520px) {
        font-size: 32px;
        text-align: left;
      }
    }

  }

  &__inputs {
    margin-bottom: 37px;
    @media (max-width: 520px) {
      margin-bottom: 17px;
    }

    input {
      &::placeholder {
        color: #577980;
        @media (max-width: 520px) {
          color: #fff;
        }
      }

      font-size: 12px;
      font-weight: 400;
      line-height: 1.9;
      @media (max-width: 520px) {
        border-bottom: 1px solid #9D9D9D;
        font-size: 10px;
        padding: 0 0 11px;
      }
    }
  }

  &__checked-info {
    color: #FFF;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.35;
    max-width: 420px;

    & + label {
      span {
        &::before {
          @media (max-width: 768px) {
            border-radius: inherit;
          }
          @media (max-width: 520px) {
            width: 13px;
            height: 13px;
          }
        }

        @media (max-width: 520px) {
          font-size: 9px;
          line-height: 1.3;
        }
      }
    }

    @media (max-width: 520px) {
      display: none;
      & + label {
        margin-top: 0;
      }
    }
  }

  &__btn {
    margin-bottom: 22px;
    @media (max-width: 520px) {
      margin-bottom: 15px;
      text-transform: initial;
    }

    & + label {
      flex-direction: column;
    }
  }
}