.comfort {
    background-color: #F8F8F8;
    padding-top: 50px;
}

.comfort__header {
    display: flex;
    justify-content: space-between;
}

.comfort-title {
    font-size: 77px;
    font-weight: 700;
    color: var(--mainColor);
    margin-bottom: 80px;
    text-transform: uppercase;
}

.comfort-title span {
    color: #141414;
    font-size: 54px;
    font-weight: 300;
    display: block;
    margin-left: 50px;
    text-transform: lowercase;
}

.comfort-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.comfort-item {
    display: grid;
    grid-template-columns: 370px 1fr 250px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #CBCBCB;
    overflow: hidden;
}

/*.comfort-item:nth-child(n+5) {*/
/*    display: none;*/
/*}*/

.comfort-item-img {
    width: 371px;
    height: 265px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.comfort-item-info {
    margin-left: 50px;
}

.comfort-item-info-title {
    font-size: 34px;
    font-weight: 500;
    color: #152E33;
    margin-bottom: 40px;
}

.comfort-item-info-content {
    display: flex;
}

.comfort-item-info-content-left {
    border-right: 1px solid #CBCBCB;
    padding-right: 40px;
}

.comfort-item-info-content-right {
    padding-left: 40px;
}

.comfort-item-info-text {
    font-size: 22px;
    color: #141414;
}

.comfort .comfort-item-btn {
    text-transform: uppercase;
    margin-left: auto;
    margin-bottom: auto;
    background-color: var(--mainColor);
    border-radius: 50px;
    padding: 20px 60px;
    font-size: 11px;
    font-weight: 500;
    margin-top: 40px;
    margin-right: 40px;
}

.comfort__button-full {
    display: flex;
    justify-content: center;
}

.comfort__button-full button {
    color: #FFFFFF;
    background-color: #123539;
    padding: 32px 38px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    border-radius: 666px;
    margin: 26px 0;
    cursor: pointer;
}

.comfort__buttons {
    display: none;
}

.comfort-presentation {
    background-image: url("/public/img/presentation.png");
    max-width: 747px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
}

.comfort-presentation.active {
    display: block;
}

.comfort-presentation__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    font-size: 43px;
    line-height: 61px;
    font-weight: 500;
    margin-top: 150px;
    margin-bottom: 61px;
}

.comfort-presentation__header p {
    font-size: 34px;
    line-height: 37px;
    font-weight: 400;
}

.comfort__form {
    margin-left: 72px;
}

.comfort__form__inputs {
    display: flex;
    flex-direction: column;
    gap: 55px;
    margin-bottom: 44px;
}

.comfort__form__input input {
    border: none;
    background: none;
    border-bottom: 1px solid #FFFFFF;
    color: #577980;
    padding-bottom: 18px;
    padding-left: 14px;
    font-size: 15px;
    line-height: 29px;
    width: 85%;
    cursor: pointer;
}

.comfort__form button {
    background-color: var(--mainColor);
    color: #141414;
    font-size: 14px;
    font-weight: 500;
    border-radius: 666px;
    cursor: pointer;
    height: 89px;
    width: 85%;
    margin-bottom: 27px;
}

.comfort__form__info {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    width: 70%;
    margin-bottom: 15px;
}

.comfort__form-politic {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

.comfort__form-politic span {
    display: flex;
    gap: 10px;
    margin-bottom: 117px;
}

.comfort__form-politic span::before {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    border: 1px solid #CBCBCB;
    border-radius: 3px;
}

.comfort__form-politic input:checked+span::before {
    background-image: url("/public/img/check-white.png");
    background-position: center;
    background-repeat: no-repeat;
}


@media (max-width: 1240px) {
    .comfort-item {
        grid-template-columns: 300px 1fr 250px;
    }

    .comfort-item-img {
        width: 300px;
    }

}

@media (max-width: 1170px) {
    .comfort .comfort-title {
        font-size: 50px;
    }

    .comfort-item {
        grid-template-columns: 240px 1fr 250px;
    }

    .comfort-item-img {
        width: 239px;
        height: 210px;
    }

    .comfort .comfort-item-info-title {
        font-size: 28px;
    }

    .comfort-item-info {
        margin-left: 25px;
    }

    .comfort-item-info-content-left {
        padding-right: 20px;
    }

    .comfort-item-info-content-right {
        padding-left: 20px;
    }

    .comfort-item-btn {
        margin-top: 20px;
        margin-right: 20px;
        padding: 20px 45px;
    }

    .comfort__button-full button {
        padding: 20px 38px;
    }
}

@media (max-width: 1110px) {
    .comfort-presentation__header {
        font-size: 35px;
        margin-bottom: 35px;
    }

    .comfort-presentation__header p {
        font-size: 23px;
    }

    .comfort__form {
        margin-left: 50px;
    }

    .comfort__form__inputs {
        gap: 20px;
        margin-bottom: 30px;
    }

    .comfort__form__input input {
        padding-bottom: 12px;
        font-size: 12px;
    }

    .comfort__form button {
        font-size: 12px;
        height: 65px;
        margin-bottom: 20px;
    }

    .comfort__form__info {
        font-size: 12px;
    }

    .comfort__form-politic {
        font-size: 12px;
    }

    .comfort__form-politic span {
        margin-bottom: 40px;
    }
}

@media (max-width: 1060px) {
    .comfort .comfort-title {
        margin-bottom: 40px;
    }
}

@media (max-width: 980px) {
    .comfort-item-info-content-right {
        display: none;
    }

    .comfort-item-info-content-left {
        border: none;
        padding-right: none;
    }
}

@media (max-width: 900px) {
    .comfort__form {
        margin-left: 30px;
    }
}

@media (max-width: 880px) {
    .comfort .comfort-item-info-title {
        font-size: 24px;
    }

    .comfort-item-info-title {
        margin-bottom: 20px;
    }

    .comfort-item-btn {
        padding: 12px 40px;
    }

}

@media (max-width: 768px) {

    .comfort {
        background-color: rgba(21, 46, 51, 1);
    }

    .comfort-item {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    .comfort .comfort-title {
        margin-bottom: 20px;
    }

    .comfort .comfort-title span {
        font-size: 38px;
    }

    .comfort .comfort-title span,
    .comfort-item-info-title,
    .comfort-item-info p {
        color: #FFFFFF;
    }

    .comfort .comfort-item-info-text svg {
        fill: white;
        background: none;
    }

    .comfort-item-img {
        width: 100%;
        height: 350px;
        margin-bottom: 30px;
        background-size: cover;
    }

    .comfort-item-info-content-text p {
        line-height: 26px;
    }

    .comfort .comfort-item-btn {
        display: flex;
        margin-right: 0;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 30px;
        padding: 20px 80px;
    }

    .comfort__button {
        width: 50px;
        height: 50px;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        background: none;
        border-radius: 99px;
        font-size: 24px;
        cursor: pointer;
        padding-bottom: 4px;
    }

    .comfort-presentation {
        width: 70%;
    }

    .comfort-presentation__header {
        font-size: 25px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    .comfort-presentation__header p {
        font-size: 15px;
    }

    .comfort__form__inputs {
        gap: 15px;
        margin-bottom: 25px;
    }

    .comfort__form__input input {
        padding-bottom: 8px;
        font-size: 11px;
    }

    .comfort__form button {
        font-size: 10px;
        height: 55px;
        margin-bottom: 15px;
    }

    .comfort__form__info {
        font-size: 10px;
    }

    .comfort__form-politic {
        font-size: 10px;
    }

    .comfort__form-politic span {
        margin-bottom: 30px;
    }

    .comfort__form-politic input:checked+span::before {
        background-image: url("/public/img/svg/check-yellow.svg");
        background-position: center;
        background-repeat: no-repeat;
    }
}


@media (max-width: 490px) {
    .comfort .comfort-title {
        font-size: 35px;
    }

    .comfort .comfort-title span {
        font-size: 25px;
    }

    .comfort-item {
        align-items: flex-start;
    }

    .comfort-item-img {
        height: 250px;
    }

    .comfort .comfort-item-info-title {
        font-size: 19px;
    }

    .comfort .comfort-item-info-text {
        font-size: 13px;
    }

    .comfort .comfort-item-btn {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
        margin-bottom: 20px;
        padding: 20px 80px;
        font-size: 9px;
        align-self: center;
    }

    .comfort__button-full button {
        font-size: 11px;
    }
}

@media (max-width: 450px) {
    .comfort-presentation {
        width: 85%;
    }

    .comfort__form__inputs {
        gap: 10px;
        margin-bottom: 20px;
    }

    .comfort__form__input input {
        padding-bottom: 2px;
        padding-left: 10px;
        font-size: 9px;
    }

    .comfort__form button {
        font-size: 8px;
        height: 48px;
    }

    .comfort__form__info {
        font-size: 9px;
        line-height: 15px;
        width: 80%;
    }

    .comfort__form-politic {
        font-size: 9px;
        line-height: 15px;
    }

    .comfort__form-politic span {
        margin-bottom: 20px;
    }
}