@use '../../components/styles/mixins.scss' as *;

.map {
  border: 1px solid #E0BE74;
  background: #fff;
  height: 551px;
  @media (max-width: 1050px) {
    margin-bottom: 50px;
  }
  @media (max-width: 996px) {
    height: 459px;
  }
  @media (max-width: 768px) {
    height: 389px;
    margin-bottom: 35px;
  }
  @media (max-width: 520px) {
    height: 339px;
    margin-bottom: 70px;
  }

  [aria-label="basic tabs example"] {
    justify-content: center;
    width: 100%;
    background: #fff;
    padding: 10px 0 30px;
    gap: 75px;
    @media (max-width: 768px) {
      gap: 40px;
    }
    @media (max-width: 520px) {
      padding: 0 0 20px;
      gap: 30px;
    }
  }

  &__mapYan {
    background: #fff;
  }

  &__container {
    margin: 0 auto;
    position: relative;
    height: 100%;

    & > div {
      height: 100%;
    }

    @include tablet {

    }

    @include mobile {


    }
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-size: 54px;
    font-weight: 300;
    line-height: 62px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: 54px;
    margin-left: 35px;

    @include tablet {
      font-size: 38px;
      margin-bottom: 30px;
      padding: 0;
    }

    @include mobile {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
      padding: 0 23px;
      margin-bottom: 20px;
      max-width: 270px;

    }


    span {
      font-family: "Bickham Script Three";
      font-size: 111px;
      font-weight: 400;
      line-height: 62px;
      color: #E0BE74;
      margin-bottom: -19px;
      position: relative;
      left: -210px;

      @include tablet {
        font-size: 90px;
        text-transform: none;
      }

      @include mobile {
        font-family: "PT Sans Caption", sans-serif;
        font-size: 22px;
        font-weight: 700;
        line-height: 62px;
        text-align: left;
        text-transform: uppercase;
        left: 30px;
      }
    }
  }

  &__wrapper {
    padding-top: 50px;

    @include mobile {
      padding-top: 62px;
    }

    img {
      width: 100%;

      @include mobile {
        min-height: 300px;
        object-fit: cover;
      }
    }
  }
}

.ymaps-2-1-79-balloon__content {
  padding: 0 !important;
  background: transparent !important;
  margin: 0 !important;
  max-height: 300px;
  overflow: overlay;
  overflow-x: hidden;
}

.ymaps-2-1-79-balloon__content > ymaps {
  width: 600px !important;
  display: inline !important;
}

.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__close-button {
  background-position: center;

}

.ymaps-2-1-79-balloon__close {
  float: none !important;
  position: relative;
  top: 0;
  z-index: 2;
  left: 565px;
  width: fit-content;
  display: block;
}

@media (max-width: 767px) {

  .ymaps-2-1-79-balloon {
    width: 196px;
    box-shadow: none !important;
  }

  .ymaps-2-1-79-balloon__content > ymaps {
    width: 179px !important;
  }

  .mapCard__wrapper {
    gap: 10px;
    padding: 10px 15px;
  }

  .mapCard__title {
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }

  .mapCard__image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .mapCard {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 177px;
    min-width: initial;
  }

  .mapCard__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mapCard__container p {
    margin: 0;
    font-size: 8px;
    font-weight: 400;
    line-height: 14px;
  }

  .mapCard__price {
    font-size: 8px;
    font-weight: 400;
    line-height: 14px;
  }

  .mapCard__link {
    display: flex;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    line-height: 6px;
    letter-spacing: 0.1em;
    justify-content: center;
    background-color: rgba(224, 190, 116, 1);
    padding: 16px 0;
    color: #ffffff;
    max-width: 179px;
  }

  .ymaps-2-1-79-balloon_layout_panel {
    background-color: transparent !important;
  }

  .mapCard--accent + .mapCard__link {
    background-color: rgba(18, 53, 57, 1);
    color: #ffffff;
  }

  .ymaps-2-1-79-balloon__close-button {
    background-position: center;

  }

  .ymaps-2-1-79-balloon__close {
    float: none !important;
    left: 150px;
  }
}

.ymaps-2-1-79-balloon__layout {
  background-color: transparent !important;
}

.ymaps-2-1-79-balloon-pane {
  top: -180px;
}

.ymaps-2-1-79-map {
  width: 100% !important;
  @media (max-width: 996px) {
    height: 360px !important
  }
  @media (max-width: 768px) {
    height: 320px !important
  }
  @media (max-width: 520px) {
    height: 268px !important
  }
}

.blockRoom {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #123539;
  margin-bottom: auto;
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 130.707%;

  @media (max-width: 996px) {
    font-size: 20px;
    gap: 25px;
    margin-bottom: 25px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    gap: 22px;
    margin-bottom: 20px;
  }
  @media (max-width: 520px) {
    font-size: 15px;
    gap: 18px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  svg {
    @media (max-width: 768px) {
      width: 42px;
      height: 14px;
    }
    @media (max-width: 520px) {
      width: 35px;
      height: 11px;
    }
  }
}

.blockImage {
  max-width: 542px;
  margin: 0 auto;

  img {
    width: 100%;
    height: 334px;
    object-fit: contain;
    @media (max-width: 996px) {
      height: 290px;
    }
    @media (max-width: 768px) {
      height: 260px;
    }
    @media (max-width: 520px) {
      height: 220px;
    }
  }
}

.plan {
  padding: 31px 37px;
  @media (max-width: 520px) {
    padding: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.btnsMapArrow {
  position: absolute;
  right: 25px;
  bottom: 20%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  gap: 10px;

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    transition: .3s;
    &:hover{
      opacity: 0.9;
    }
  }
}