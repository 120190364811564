@use '../../components/styles/mixins.scss' as *;

.feedBack__wrapper {
    position: relative;
    background-color: #FFFFFF;
    padding: 47px 0;
    margin-bottom: 97px;

    border-top: 1px solid #E0BE74;
    border-bottom: 1px solid #E0BE74;
    margin-left: -50vw; // Выход за левый край
    margin-right: -50vw; // Выход за правый край
    padding-left: 50vw; // Возвращаем контент на место
    padding-right: 50vw; // Возвращаем контент на место
    @media (max-width: 768px){
        background-color: transparent;
        padding-top: 40px;
        input{
            background-color: transparent;
        }
    }

    @include mobile {
        margin-bottom: 0px;
        border: none;
        padding-top: 30px;
    }
    .button__accent{
        color: #273E42;
    }
    .custom-checkbox{
        margin: 0;
    }
    .custom-checkbox__label{
        max-width: 100%;
    }

    &>.feedBack__content {
        @include container;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1080px) {
            flex-direction: column;
        }
    }

    .feedBack__header {
        position: relative;
        left: 100px;
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        font-size: 36px;
        font-weight: 300;
        line-height: 43.88px;
        color: #141414;
        align-self: center;
        align-items: flex-end;
        padding-right: 215px;

        @include tablet {
            font-size: 28px;
            line-height: 35px;
            padding-right: 100px;
            left: 75px;
        }

        @media (max-width: 1080px) {
            margin-bottom: 50px;
            left: 0;
        }

        @include mobile {
            font-size: 20px;
            line-height: 25px;
            padding-right: 0;
            margin-bottom: 25px;
        }


        span {
            position: relative;
            left: -50px;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 52px;
            font-weight: 700;
            line-height: 62px;
            color: #264C54;
            text-align: left;
            text-transform: uppercase;
            z-index: 2;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 65%;
                height: 230%;
                transform: translate(-120%, -50%) rotate(-40deg);
                border: 2px solid #E0BE74;
                border-radius: 50%;
                pointer-events: none;
                z-index: -1;
                @include tablet {
                    width: 65%;
                    height: 180%;
                }

                @media (max-width: 1080px) {
                    content: none;
                }
            }

            @include tablet {
                font-size: 42px;
            }

            @include mobile {
                font-size: 32px;
            }

            @media(max-width:380px) {
                left: -20px;
            }
        }
    }
}