.description{
  position: relative;
  max-width: 998px;
  margin-bottom: 150px;
  &.active{
    &::after{
      background: transparent;
    }
  }
  @media (max-width: 1050px){
    margin-bottom: 100px;
  }
  @media (max-width: 768px){
    margin-bottom: 80px;
  }
  &::after{
    content: '';
    width: 120%;
    height: 201px;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 56.32%);
    position: absolute;
    bottom: -70px;
    transition: .3s;
    @media (max-width: 1050px){
      width: 100%;
    }
  }
  &__text{
    color: #747474;
    font-size: 22px;
    font-weight: 400;
    line-height: 130%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    line-clamp: 7;
    -webkit-box-orient: vertical;
    &.active{
      -webkit-line-clamp: initial;
      overflow: visible;
      margin-bottom: 40px;
      @media (max-width: 996px){
        font-size: 18px;
      }
      @media (max-width: 768px){
        margin-bottom: 30px;
        font-size: 16px;
      }
      @media (max-width: 520px){
        margin-bottom: 20px;
        font-size: 13px;
      }
      & + button{
        position: relative;
        bottom: 0;

        display: block;
        @media (max-width: 1050px){
          width: 100%;
          margin-bottom: -55px;
        }
      }
    }
    @media (max-width: 996px){
      font-size: 18px;
    }
    @media (max-width: 768px){
      font-size: 15px;
    }
    @media (max-width: 520px){
      font-size: 13px;
    }
  }
  &__more{
    position: absolute;
    color: #FFF;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    padding: 19px 56px;
    border-radius: 79px;
    border: 1px solid #152E33;
    background: #152E33;
    z-index: 2;
    bottom: -68px;
    min-width: 400px;
    z-index: 2;
    @media (max-width: 996px){
      font-size: 18px;
      bottom: -60px;
    }
    @media (max-width: 768px){
      font-size: 16px;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -50px;
    }
    @media (max-width: 520px){
      padding: 17px;
      min-width: 286px;
      font-size: 13px;
    }
  }
}