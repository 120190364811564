.header {
    /* position: absolute; */
    position: fixed;
    z-index: 100;
    top: 50px;
    left: 0;
    width: 100%;
}

.header-hidden {
    display: none; /* Смещаем шапку вверх за пределы экрана */
}

.header-body {
    background-color: #ffffff;
    border-radius: 45px;
    padding: 25px 80px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.header-burger {
    display: none;
}

.header-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-logo-img {
    max-width: 222px;
    margin-bottom: 8px;
}

.header-logo p {
    color: #123539;
}

.header-logo-text {
    font-weight: 700;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 4px;
}

.header-nav {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.header-nav a {
    color: #141414;
    font-size: 15px;
    text-align: center;
    line-height: 18px;
    transition: .3s;
}

.header-nav a:hover{
    color: #E0BE74;
}

.header-buttons {
    display: flex;
    align-items: center;
}

.header-button {
    display: inline-block;
    border: none;
    background-color: var(--mainColor);
    color: #141414;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 16px 30px;
    border-radius: 80px;
    position: relative;
    z-index: 1;
    transition: all 0.3s;
    overflow: hidden;
    cursor: pointer;
}

.header-button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--mainColor);
    border-radius: 80px;
    z-index: -2;
}

.header-button:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #F0CD82;
    transition: all 0.3s;
    border-radius: 80px;
    z-index: -1;
}

.header-button:hover {
    color: #fff;
}

.header-button:hover:before {
    width: 100%;
}

.phone-menu {
    position: relative;
    display: inline-block;
    margin-right: 25px;
}

.phone-icon-mobile {
    display: none;
}

.order-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    top: -20.5px;
    left: -14.5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease, box-shadow 0.3s ease;
    background-color: #FFFFFF;
    border-radius: 44px;
    padding: 20px 13px;
}

.phone-menu:hover .order-options {
    opacity: 1;
    visibility: visible;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25), 0px 0px 11px #818181;
    border: 2px solid #FFFFFF;
}

.phone-menu:hover .phone-icon {
    opacity: 0;
    visibility: hidden;
}

.order-options__icon {
    width: 36px;
    height: 36px;
}

/* sdfffffffffffffffffffffffffff */
.header-nav__top, .header-nav__actions {
    display: none;
}

.header-nav-close {
    align-self: flex-end;
    font-size: 40px;
    cursor: pointer;
    color: #fff;
    padding-left: 20px;
}

.header-nav-close:hover {
    color: var(--mainColor);
}

.header-button-menu {
    display: none;
}

@media (max-width: 1565px) {
    .header-nav {
        gap: 20px;
    }
}

@media (max-width: 1410px) {
    .header-logo-img {
        max-width: 180px;
        margin-bottom: 5px;
    }

    .header-logo-text {
        font-size: 9px;
        letter-spacing: 3px;
    }
}

@media (max-width: 1370px) {
    .header-body {
        padding: 25px 40px;
        gap: 25px;
    }
}

@media (max-width: 1241px) {
    .header-nav {
        gap: 9px;
    }

    .header-nav a {
        font-size: 13px;
    }

    .order-options {
        top: -11px;
        left: -10.5px;
        padding: 10px 7px;
    }

    .phone-men {
        margin-right: 15px;
    }

    .header-button {
        padding: 15px;
    }
}

@media (max-width: 1080px) {
    .header-burger {
        display: block;
        width: 35px;
        height: 20px;
        position: relative;
        cursor: pointer;
    }

    .header-burger::before,
    .header-burger::after {
        content: '';
        position: absolute;
        left: 0;
        background-color: #ffffff;
        height: 3px;
        width: 100%;
    }

    .header-burger::before {
        top: 0;
    }

    .header-burger::after {
        bottom: 0;
    }

    .header-button{
        display: none;
    }
    
    
    .header {
        background-color: rgba(18, 53, 57, 0.5);
        top: 0;
        left: 0;
    }
    
    .header-body {
        background-color: transparent;
        border-bottom: 1px solid var(--mainColor);
        border-radius: 0;
        margin-bottom: 20px;
    }

    .header-logo-img {
        width: 235px;
        max-width: none;
    }

    .header-logo p {
        color: #FFFFFF;
    }

    .phone-menu {
        margin-right: 40px;
    }

    .phone-icon {
        display: none;
    }

    .phone-icon-mobile {
        display: block;
    }

    .phone-icon-mobile img {
        width: 30px;
        height: 30px;
        margin-top: 7px;
    }

    .order-options {
        top: -10px;
        left: -10px;
        padding: 10px 7px;
    }

    .phone-menu:hover .phone-icon-mobile {
        opacity: 0;
        visibility: hidden;
    }

    .header-logo-text {
        color: #ffffff;
        font-size: 12px;
    }

    .header-nav__top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 20px;
    }

    .header-nav__top-logo {
        align-self: center;
    }

    .header-nav__top img {
        height: 22px;
    }

    .header-nav .header-button-menu {
        display: flex;
        justify-content: center;
        color: #141414;
        text-align: center;
        margin-bottom: 40px;
    }

    .header-nav a {
        text-align: left;
        color: #fff;
        text-decoration: none;
        font-size: 18px;
        padding-left: 20px;
        width: 100%;
    }

    .header-nav {
        position: fixed;
        top: 0;
        right: 0;
        width: 50%;
        flex-direction: column;
        padding: 20px 35px;
        background-color: #292929;
        color: #fff;
        z-index: 1000;
        gap: 50px;
        transition: all .4s ease-out;
        align-items: flex-start;
        transform: translateX(200%);
    }

    .header-nav.active {
        display: flex;
        justify-content: flex-start;
        height: auto;
        transform: translateX(0);
    }

    .header-nav__logo-text{
        color: var(--mainColor);
    }

    .header-nav__actions {
        display: flex;
        flex-direction: column;
        gap: 7px;
        font-size: 9px;
        font-weight: 500;
        line-height: 11px;
        padding-left: 20px;
        align-items: flex-start;
    }

    .header-nav__actions button {
        display: flex;
        gap: 5px;
        border: 1px solid #CBCBCB;
        background: none;
        border-radius: 22px;
        padding: 12px;
        align-items: center;
        color: #FFFFFF;
    }
}

@media(max-width: 850px) {
    .header-nav {
        width: 70%;
    }

    .header-nav-close {
        padding-left: 40px;
    }
}

@media(max-width: 767px) {

    .header-body {
        padding: 10px;
        margin-bottom: 10px;
    }

    .header-logo-img {
        max-width: 140px;
        margin-bottom: 2px;
    }

    .header-logo-text {
        font-size: 10px;
        letter-spacing: 0px;
    }

    .header-body .header-nav .header-button-menu {
        font-size: 9px;
        line-height: normal;
    }

    .phone-icon-mobile img {
        width: 20px;
        height: 20px;
        margin-top: 7px;
    }

    .header-burger {
        width: 25px;
        height: 15px;
    }
}

@media(max-width:675px) {
    .header-nav__top img {
        height: 16px;
    }
    .header-nav__top,  
    .header-nav__actions{
        padding-left: 0;
    }
    .header-nav__logo-text {
        font-size: 12px;
    }
    .header-nav-close {
        padding-left: 30px;
    }
    .header-nav a {
        font-size: 12px;
        padding-left: 0;
    }
    .header-nav {
        gap: 25px;
    }

    .header-body .header-nav .header-button-menu {
        font-size: 11px;
        padding: 10px 5px;
    }
}

@media(max-width:570px) {
    .header-nav__logo-text {
        font-size: 11px;
    }

    .header-nav-close {
        padding-left: 20px;
        font-size: 30px;
    }

    .phone-menu {
        margin-right: 30px;
    }

    .order-options {
        top: -14px;
        left: -16px;
        padding: 10px 7px;
    }

}

@media(max-width:390px) {

    .header-nav__top img {
        height: 14px;
    }

    .header-nav__logo-text {
        font-size: 10px;
    }

    .header-burger {
        width: 25px;
        height: 12px;
    }
}