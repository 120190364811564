.popap__form{
    display: none;
}

.popap__form.active {
    display: block;
}

.popap__form {
    background-image: url("/public/img/apartmentSelection.png");
    background-size: cover;
    max-width: 747px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
    overflow: hidden;
    height: auto;
    max-height: 100%;
    overflow-y: scroll; /* Разрешаем вертикальный скроллинг */
    scrollbar-width: none; /* Firefox: убираем скроллбар */
}

.popap__form::-webkit-scrollbar {
    display: none;
}


@media (max-height: 1200px) {
    .popap__form {
        clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 850px) {
    .popap__form {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 670px) {
    .popap__form {
        clip-path: polygon(0 13%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 530px) {
    .popap__form {
        clip-path: polygon(0 16%, 100% 0, 100% 100%, 0 100%);
    }
}

.popap__form__form-title {
    color: #FFFFFF;
}

.popap__form__form-title h2 {
    font-size: 53px;
    font-weight: 500;
    line-height: 61px;
    padding-top: 80px;
}

.popap__form__form-title p {
    font-size: 28px;
    line-height: 37px;
    padding-top: 15px;
    padding-bottom: 70px;
}

.popap__formError{
    margin-top: 25px;
    display: none;
}

.popap__form .button {
    width: 100%;
    height: 90px;
}

@media(max-width: 1440px) {
    .popap__form {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .popap__form {
        width: 80%;
    }

    .popap__form .form__container {
        padding: 40px;
    }

    .popap__form__form-title h2 {
        font-size: 35px;
        line-height: normal;
    }

    .popap__form__form-title p {
        font-size: 25px;
        padding-bottom: 40px;
    }
}

@media(max-width: 768px) {
    .popap__form__form-title h2 {
        padding-top: 75px;
    }

    .popap__form__form-title p {
        padding-bottom: 25px;
    }


    .popap__form .form-label-befor-block .form-label-input span::before {
        display: block;
    }

    .popap__form .button  {
        height: 75px;
        margin-top: 0px;
        font-size: 12px;
    }

}

@media(max-width: 554px) {

    .popap__form .form__container {
        padding: 20px;
    }

    .popap__form .form-label-select-content {
        background-size: 15px;
    }

    .popap__form__form-title h2 {
        font-size: 25px;
        line-height: normal;
    }

    .popap__form__form-title p {
        font-size: 16px;
        padding-bottom: 28px;
        line-height: 21px;
    }

    .popap__form .form-label-title {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .popap__form .form-label-flex {
        gap: 6px;
    }

    .popap__form .form-label-flex .form-label-input span,
    .popap__form .form-label-text input,
    .popap__form .form-label-text input::placeholder,
    .popap__form .form-label-select-content {
        font-size: 11px;
    }

    .popap__form .add-small-margin {
        padding-top: 16px;
    }

    .popap__form .popap__formError{
        margin-top: 15px;
        margin-bottom: 0;
    }

    .popap__form .form__button {
        max-height: 52px;
        font-weight: 600;
        font-size: 8px;
        margin-top: 0;
    }

    .popap__form .form-label-politic {
        margin-top: 20px;
    }

    .popap__form .form-label-politic span {
        font-size: 9px;
    }

    .popap__form .form-label-befor-block .form-label-input span::before {
        width: 12px;
        height: 12px;
    }
}

@media(max-width: 450px) {

    .popap__form__form-title h2 {
        padding-top: 50px;
    }
    .popap__form__form-title p {
        padding-bottom: 30px;
    }

    .popap__form .form-label-flex-column .form-label-input::before {
        width: 90%;
    }

    .popap__form .form-label-flex-column .form-label-input2-first::before {
        width: 65%;
    }

    .popap__form .button {
        margin-top: 15px;
        height: 57px;
        font-size: 9px;
    }
}

@media(max-width: 395px) {
    .popap__form .form-label-flex-column .form-label-input::before {
        width: 100%;
    }

    .popap__form .form-label-flex-column .form-label-input2-first::before {
        width: 75%;
    }
}

@media(max-width: 345px) {
    .popap__form .form-label-flex-column .form-label-input::before {
        width: 95%;
    }

    .popap__form .form-label-flex-column .form-label-input2-first::before {
        width: 85%;
    }
}