.single {
  &__container {
    max-width: 1765px;
    padding: 0 27px;
  }

  &__product {
    display: flex;
    gap: 41px;
    margin-bottom: 20px;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      margin-bottom: 30px;
      gap: 25px;
    }
    @media (max-width: 520px) {
      gap: 0px;
    }
  }

  &__product_apartment {
    margin-bottom: 50px;

    & + .single__product > .single__data.single__info {
      @media (min-width: 1050px) {
        padding-top: 50px;
      }
    }
  }

  &__info {
    flex: 1 1 62%;
    max-width: 62%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1050px) {
      max-width: 100%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    flex: 1 1 35.4%
  }

  &__right_apartment {
    display: flex;

    & > div {
      margin-top: auto;
      width: 100%;
    }
  }

  &__detail-wrapper {
    position: relative;
  }

  &__detail {
    background: url("../../../public/img/single/line.png") 0 0 / cover no-repeat;
    margin-bottom: 50px;
    padding: 29px 32px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 996px) {
      padding: 25px 32px 8px;
      background-size: contain;
      background-position: top;
    }
    @media (max-width: 768px) {
      padding: 18px 31px 8px;
      margin-bottom: 68px;
    }
  }

  &__price {
    color: #FFF;
    font-size: 52px;
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 40px;
    width: 100%;
    @media (max-width: 1330px) {
      font-size: 48px;
    }
    @media (max-width: 1240px) {
      font-size: 36px;
    }
    @media (max-width: 996px) {
      margin-bottom: 30px;
      font-size: 34px;
    }
    @media (max-width: 768px) {
      margin-bottom: 25px;
      font-size: 32px;
    }
    @media (max-width: 520px) {
      margin-bottom: 18px;
      font-size: 24px;
    }
  }

  &__actions {
    display: flex;
    margin-bottom: 30px;
    gap: 15px;
    @media (max-width: 996px) {
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      gap: 10px;
      margin-bottom: 15px;
    }
    @media (max-width: 520px) {
      gap: 5px;
      margin-bottom: 10px;
    }

    button {
      @media (max-width: 520px) {
        font-size: 9px;
        padding: 6px 8px;
      }
    }

    & + .description {
      p {
        height: 220px;
        @media (max-width: 1024px) {
          height: 180px;
        }
        @media (max-width: 768px) {
          height: 160px;
        }
        @media (max-width: 520px) {
          height: 135px;
        }
      }

      &.active {

        p {
          height: auto;
        }
      }
    }
  }


  &__prices {
    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
    @media (max-width: 520px) {
      margin-bottom: 20px;
    }

    .single-wrapper {
      gap: 40px;
      align-items: center;
      padding: 110px 0 0 43px;
      @media (max-width: 996px) {
        gap: 30px;

      }
      @media (max-width: 768px) {
        gap: 20px;
        padding: 110px 0 0 15px;

      }
      @media (max-width: 520px) {
        gap: 10px;
        justify-content: space-between;
      }
    }

    .single-info {
      flex: initial;
      padding-top: 20px;

      &:first-child {

        h5 {
          font-size: 36px;
          font-weight: 500;
          @media (max-width: 1280px) {
            font-size: 29px;
          }
          @media (max-width: 996px) {
            font-size: 25px;
          }
          @media (max-width: 768px) {
            font-size: 22px;
          }
          @media (max-width: 520px) {
            font-size: 19px;
          }
        }
      }

      &:last-child {
        transform: scale(0.7);

        h5 {
          font-size: 28px;
          @media (max-width: 996px) {
            font-size: 24px;
          }
          @media (max-width: 768px) {
            font-size: 22px;
          }
          @media (max-width: 520px) {
            font-size: 18px;

          }
        }
      }
    }
  }

  &__prices_apartment {
    .single-wrapper {
      @media (max-width: 996px) {
        padding: 75px 0 0 25px;
      }
      @media (max-width: 768px) {
        padding: 60px 0 0 20px;
      }
      @media (max-width: 520px) {
        padding: 45px 0 0 15px;
      }
    }
  }

  &__attributes {
    @media (min-width: 1050px) {
      padding-right: 92px;
    }
    @media (max-width: 1440px) and (min-width: 1050px) {
      padding-right: 60px;
    }
    @media (max-width: 1280px) and (min-width: 1050px) {
      padding-right: 50px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    gap: 40px 34px;
    margin-bottom: 200px;
    @media (max-width: 996px) {
      gap: 30px;
      margin-bottom: 150px;
    }
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      margin-bottom: 120px;
    }
    @media (max-width: 520px) {
      gap: 20px;
      margin-bottom: 90px;
    }
  }

  article {
    flex: 0 1 calc(33.33% - 23px);
    @media (max-width: 1520px) {
      flex: 0 1 calc(33.33% - 43px);
    }
    @media (max-width: 1240px) {
      flex: 1 1 calc(50% - 30px);
    }
    @media (max-width: 768px) {
      flex: 1 1 100%;
    }
  }
}

.detail-apartment {
  display: flex;
  flex-direction: column;
  gap: 19px;
  margin-bottom: 44px;
  @media (max-width: 768px) {
    margin-bottom: 35px;
  }
  @media (max-width: 520px) {
    gap: 10px;
    margin-bottom: 23px;
  }

  &__item {
    display: flex;
    gap: 50px;
    @media (max-width: 768px) {
      gap: 35px;
    }
    @media (max-width: 520px) {
      gap: 23px;
    }
  }

  &__name {
    color: #F9F9F9;
    font-size: 18px;
    font-weight: 300;
    white-space: nowrap;

    @media (min-width: 520px) {
      min-width: 100px;
    };
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 520px) {
      font-size: 9px;
      min-width: 50px;
    }
  }

  &__desc {
    color: #F9F9F9;
    font-size: 22px;
    font-weight: 400;
    max-width: 270px;
    width: 50%;
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 520px) {
      font-size: 10px;
    }
  }


}

.single__price-info {
  @media (max-width: 520px) {
    padding: 0 18px;
  }

  & + a {
    padding: 28px;
    width: 100%;
    font-size: 20px;
    @media (max-width: 1280px) {
      font-size: 16px;
    }
    @media (max-width: 1080px) {
      display: block;
      padding: 22px;
      font-size: 14px;
    }
    @media (max-width: 520px) {
      padding: 18px;
      font-size: 11px;
    }
  }
}

.title-block {
  color: #141414;
  font-size: 44px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 18px;
  @media (max-width: 996px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    color: #000;
    margin-bottom: 25px;
  }
  @media (max-width: 520px) {
    font-size: 25px;
  }
}