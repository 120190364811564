@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
}

:root {
  --mainColor: #E0BE74;
  --mainColor2: #123539;
}

#root{
  overflow-x: hidden;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1700px;
  padding: 0 20px;
  margin: 0 auto;
}

.title {
  font-size: 77px;
  font-weight: 700;
  color: var(--mainColor);
  margin-bottom: 80px;
}

.title span {
  color: #141414;
  font-size: 54px;
  font-weight: 300;
  display: block;
}

body{
  background: #F8F8F8;
}

.button {
  background-color: var(--mainColor);
  color: #181818;
  border-radius: 666px;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: all 0.3s;
  overflow: hidden;
}

.button:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--mainColor);
  border-radius: 666px;
  z-index: -2;
}

.button:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #F0CD82;
  transition: all 0.3s;
  border-radius: 666px;
  z-index: -1;
}

.button:hover {
  color: #fff;
}

.button:hover:before {
  width: 100%;
}
.loader-wrapper{
  margin-bottom: 45px;
}
.success {
  background-image: url("../public/img/thanks-bg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 858px;
  background-size: cover;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  color: #FFFFFF;
  display: none;
}

.success.active {
  display: block;
}

.success__wrapper {
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 150px 80px;
}

.success__wrapper h2 {
  font-size: 53px;
  font-weight: 500;
  line-height: 61px;
  margin-bottom: 30px;
}

.success__wrapper p {
  font-size: 28px;
  line-height: 37px;
}

.form {
  max-width: 610px;
}

.form__container {
  padding: 77px;
}

.form-title {
  color: #FFFFFF;
}

.form-title h2 {
  font-size: 53px;
  font-weight: 500;
  line-height: 61px;
  padding-top: 80px;
}

.form-title p {
  font-size: 28px;
  line-height: 37px;
  padding-top: 15px;
  padding-bottom: 70px;
}

.form-label-row {
  margin-bottom: 20px;
}

.form-label-column {
  flex-direction: column;
}

.form-label-title {
  color: #ffffff;
  margin-bottom: 15px;
  font-weight: 500;
  size: 15px;
  line-height: 29px;
}

.form-label-flex {
  display: flex;
  gap: 18px;
}

.form-label-input-wrapper {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
  cursor: pointer;
}

.form-label-input-wrapper input[type="checkbox"],
.form-label-input-wrapper input[type="radio"],
.form-label-politic input {
  width: 0px;
  height: 0px;
  visibility: hidden;
  display: none;
}

.form-label-input {
  color: #ffffff;
}

.form-label-input::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #CBCBCB;
  border-radius: 40px;
}

.form-label-input span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  color: inherit;
  font-size: 13px;
  position: relative;
}

.form-label-politic {
  cursor: pointer;
  margin-top: 25px;
}

.form-label-politic span {
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-label-politic input:checked+span::before {
  background-image: url("../public/img/check-white.png");
  background-position: center;
  background-repeat: no-repeat;
}

.form-label-input span::before,
.form-label-politic span::before {
  content: '';
  display: block;
  width: 17px;
  height: 17px;
  border: 1px solid #CBCBCB;
  border-radius: 3px;
}

.form-label-input-wrapper input[type="checkbox"]:checked+.form-label-input,
.form-label-input-wrapper input[type="radio"]:checked+.form-label-input {
  color: var(--mainColor);
}

.form-label-input-wrapper input[type="checkbox"]:checked+.form-label-input::before,
.form-label-input-wrapper input[type="radio"]:checked+.form-label-input::before {
  border: 1px solid var(--mainColor);
}

.form-label-input-wrapper input[type="checkbox"]:checked+.form-label-input span::before,
.form-label-input-wrapper input[type="radio"]:checked+.form-label-input span::before {
  border: 1px solid var(--mainColor);
  background-image: url("../public/img/check.png");
  background-position: center;
  background-repeat: no-repeat;
}

.form .button {
  width: 100%;
  height: 90px;
  font-size: 11px;
}

@media (min-width: 1440px) {
  .form .button {
    width: 100%;
    height: clamp(75px, 5vh, 90px);
  }
}

.form-label-select {
  position: relative;
  width: 100%;
}

.form-label-select:first-child {
  margin-right: 35px;
}

.form-label-select-content {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  padding: 0 14px 18px;
  font-size: 15px;
  color: var(--mainColor);
  cursor: pointer;
  background-image: url("../public/img/arrow.png");
  background-position: right top 8px;
  background-repeat: no-repeat;
  appearance: none;
}

.form-label-select-content option{
  color: #141414;
}

.form-label-text {
  position: relative;
  width: 100%;
}

.form-label-text:first-child {
  margin-right: 35px;
}

.form-label-text input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  padding: 0 14px 18px;
  font-size: 15px;
  color: var(--mainColor);
  cursor: pointer;
}

.form-label-text input::placeholder {
  font-size: 15px;
  color: var(--mainColor);
}

.first-block__formError {
  display: none;
}

.formError {
  color: #FFFFFF;
  float: right;
  border: 1px solid #1C3B41;
  padding: 5px 15px;
  border-radius: 50px;
  background-color: #1C3B41;
}

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none !important;
  margin-top: 30px !important;
  position: static !important;
}

.slick-dots button {
  width: 17px !important;
  height: 17px !important;
  background-color: #D9D9D9 !important;
  border-radius: 50%;
  border: none !important;
  color: transparent !important;
  cursor: pointer !important;
}

.slick-dots .slick-active button {
  background-color: var(--mainColor2) !important;
}
.slick-dots li button:before{
  content: none !important;
}

.add-small-margin {
  padding-top: 27px;
}

@media(max-width: 1650px) {
  .success__wrapper {
    padding: 0 130px 80px;
  }

  .success__wrapper h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .success__wrapper p {
    font-size: 20px;
    line-height: 30px;
  }
}

@media(max-width: 1310px) {
  .success__wrapper {
    padding: 0 46px 80px;
  }
}

@media(max-width: 980px) {
  .success__wrapper {
    padding: 0 107px 80px;
  }

  .success {
    width: 80%;
  }
}

@media (max-width: 768px) {

  .success__wrapper {
    padding: 0 46px 80px;
  }

  .form-label-politic span::before {
    border-radius: 50%;
    accent-color: var(--mainColor);
  }

  .form-label-input span::before {
    display: none;
  }

  .form-label-politic input:checked+span::before {
    background-image: url("../public/img/svg/check-yellow.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media(max-width: 610px) {
  .success {
    width: 90%;
  }

  .success__wrapper {
    margin-top: 120px;
    padding: 0 29px 50px;
  }

  .success__wrapper h2 {
    font-size: 35px;
    line-height: 40px;
  }

  .success__wrapper p {
    font-size: 18px;
    line-height: 25px;
  }

  .form-label-input-wrapper {
    padding: 12px 10px;
  }

  .form-label-flex-column .form-label-input::before {
    width: 80%;
  }

  .form-label-flex-column .form-label-input2-first::before {
    width: 60%;
  }
}

@media (max-width: 554px) {

  .formError {
    float: none;
    font-size: 14px;
    justify-content: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 450px) {
  .first-block {
    padding-bottom: 120px !important;
  }

  .form-label-select-content {
    background-size: 14px;
  }

  .success {
    width: 80%;
  }

  .success__wrapper {
    margin-top: 80px;
    padding: 0 10px 25px;
  }

  .success__wrapper h2 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .success__wrapper p {
    font-size: 12px;
    line-height: 20px;
  }

  .form-label-title {
    font-size: 13px;
    margin-bottom: 5px;
  }

  .form-label-input-wrapper {
    padding: 5px 8px;
  }

  .form-label-display-none {
    display: none;
  }

  .form-label-select-content {
    font-size: 11px;
  }

  .form-label-text input::placeholder {
    font-size: 11px;
  }

  .form-label-input span {
    font-size: 8px;
  }

  .form .button {
    margin-top: 5px;
    height: 57px;
    font-size: 9px;
  }


  .form-label-select-content {
    padding: 0 12px 10px;
  }

  .form-label-text input {
    padding: 0 12px 10px;
    font-size: 11px;
  }

  .form-label-politic span {
    font-size: 10px;
  }

  .form-label-row {
    margin-bottom: 10px;
  }

  .form-label-flex {
    gap: 5px;
  }

  /* .formError {
      font-size: 10px;
  } */

  .form-label-flex-column .form-label-input::before {
    width: 70%;
  }

  .form-label-flex-column .form-label-input2-first::before {
    width: 50%;
  }
}
@media (max-width: 350px) {
  .success__wrapper {
    margin-top: 60px;
    padding: 0 10px 20px;
  }

  .success__wrapper h2 {
    font-size: 20px;
    line-height: 20px;
  }

  .success__wrapper p {
    font-size: 9px;
    line-height: 16px;
  }
  .form-label-politic span::before {
    width: 21px;
  }
}