@use '../../components/styles/mixins.scss' as *;

.feedBackRound__wrapper {
    @include container;


    margin-bottom: 150px;

    @include tablet {
        margin-bottom: 100px;
    }

    @include mobile {
        margin-bottom: 50px;
    }


    &>.feedBackRound__content {
        display: flex;
        background-color: #FFFFFF;
        border: 1px solid #123539;
        border-radius: 552px;
        padding: 55px 55px;
        position: relative;

        @media (max-width: 1080px) {
            flex-direction: column;
        }

        @include mobile {
            background: transparent;
            margin-bottom: 0px;
            padding: 0;
            border: none
        }
        input {
            background: transparent;
            z-index: 2;
        }
    }

    .feedBackRound__header {
        position: relative;
        left: 140px;
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
        font-size: 41px;
        font-weight: 300;
        line-height: 50px;
        color: #141414;
        align-self: center;
        padding-right: 90px;

        @include tablet {
            font-size: 28px;
            line-height: 35px;
            padding-right: 100px;
        }

        @media (max-width: 1080px) {
            margin-bottom: 50px;
            padding-right: 0;
            left: 40px;
        }

        @include mobile {
            font-size: 20px;
            line-height: 25px;
            padding-right: 0;
            margin-bottom: 25px;
            left: 0;
        }

        span {
            position: relative;
            left: -140px;
            font-family: 'PT Sans Caption', sans-serif;
            font-size: 52px;
            font-weight: 700;
            line-height: 62px;
            color: #E0BE74;
            text-transform: uppercase;

            @include tablet {
                font-size: 40px;
                line-height: 45px;
            }

            @media (max-width: 990px) {
                left: -40px;
            }

            @include mobile {
                font-size: 32px;
                line-height: 34px;
            }

            @media(max-width:510px) {
                left: 0
            }
        }

        p {
            max-width: 560px;
            margin-right: 90px;

            @media (max-width: 1080px) {
                margin-right: 0;
            }

            @media (max-width: 510px) {
                margin-left: 40px;
            }

        }

    }
}