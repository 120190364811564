.first-block {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.first-block::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url("/public/img/first-block-color-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;
}

.first-block-img {
    width: 50%;
    position: relative;
    z-index: 2;
}

.first-block-img img {
    max-width: 100%;
}

.first-block-form {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-top: 170px;
}

.first-block-title {
    max-width: 610px;
    color: #ffffff;
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    margin-bottom: 20px;
}

@media (max-width: 1860px) {
    .first-block {
        padding-bottom: 50px;
    }
}

@media (max-width: 1790px) {
    .first-block {
        padding-bottom: 100px;
    }
}

@media (max-width: 1720px) {
    .first-block {
        padding-bottom: 150px;
    }
}

@media (max-width: 1650px) {
    .first-block {
        padding-bottom: 200px;
    }
}

@media (max-width:1440px) {
    .first-block-img {
        display: none;
    }

    .first-block {
        padding-bottom: 120px;
    }

    .first-block::before {
        background-image: url("/public/img/first-block-color-bg-mobile.png");
    }

    .form .button {
        height: clamp(57px, 4vh, 80px);
    }

    .first-block .form-label-politic span {
        margin-bottom: 35px;
    }
}

@media (max-width: 768px) {
    .first-block {
        padding-bottom: 150px;
    }

    .first-block-title {
        color: var(--mainColor);
        font-size: 44px;
    }

    .first-block-form {
        padding-top: 120px;
    }

    .flex-column {
        flex-direction: column;
    }
}

@media (max-width: 580px) {
    .first-block::before {
        height: 102%;
    }

    .first-block-form {
        align-items: flex-start;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 115px;
    }

    .first-block-title {
        font-size: 30px;
    }

    .first-block .form-label-politic span {
        margin-bottom: 0px;
    }
}

@media (max-width: 450px) {
    .first-block::before {
        height: 100%;
    }

    .first-block-title {
        font-size: 20px;
    }

    .first-block .formError {
        margin-bottom: 10px;
    }
}