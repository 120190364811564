.filters__item{
  color: #5B5B5B;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  line-height: 130%;
  border: none;
  background: transparent;
  padding: 23px 32px;
  &:first-child{
    padding: 23px 100px;
    @media (max-width: 996px){
      padding: 17px 80px;
    }
    @media (max-width: 768px){
      padding: 15px 60px;
    }
    @media (max-width: 520px){
      padding: 13px 20px;
    }
  }
  &:nth-child(2) {
    padding-left: 50px;
    padding-right: 50px;
    @media (max-width: 996px){
      padding-left: 35px;
      padding-right: 35px;
    }
    @media (max-width: 768px){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media (max-width: 996px){
    padding: 17px 20px;
    font-size: 16px;
  }
  @media (max-width: 768px){
    padding: 15px 20px;
    font-size: 14px;
  }
  @media (max-width: 520px){
    padding: 13px 20px;
    font-size: 11px;
  }
  &.room{
    &:not(:last-child){
      margin-right: 18px;
      @media (max-width: 1240px){
        margin-right: 12px;
      }
      @media (max-width: 996px){
        margin-right: 6px;
      }
      @media (max-width: 768px){
        margin-right: 0px;
      }
    }
  }
  &.active{
    background: #E0BE74;
    color: #273E42;
    &.room{
      color: #FFF;
      background: #152E33;
    }
  }
}