.partners{
    margin-top: 126px;
    margin-bottom: 143px;
}

.partners__header{
    display: flex;
    margin-bottom: 107px;
    justify-content: space-between;
}

.partners__title{
    display: flex;
    flex-direction: column;
    font-size: 54px;
    font-weight: 300;
    line-height: 61px;
}

.partners__title span{
    font-size: 77px;
    font-weight: 700;
    color: var(--mainColor);
    margin: 13px 0 0 42px;
    text-transform: uppercase;
}

.partners__buttons{
    margin-top: auto;
}

.partners__button{
    padding: 9px 43px;
    border: 1px solid #B5B5B5;
    background: none;
    border-radius: 22px;
    font-size: 15px;
    color: #B5B5B5;
    cursor: pointer;
}

.partners__button-padding{
    padding: 9px 55px;
    margin-right: 20px;
}

.partners__button.is-active{
    color: #141414;
    border-color: var(--mainColor);
}

.partners__buttons-left{
    display: flex;
    gap: 17px;
}
.partners__buttons-right{
    display: none;
}

.partners__button-right{
    width: 25px;
    height: 25px;
    color: #123539;
    border: 1px solid #123539;
    background: none;
    border-radius: 99px;
    cursor: pointer;
}

.partners__list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: none;
}

.partners__list.is-active{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
}

.partners__list li{
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid #CBCBCB;
    height: 200px;
}

.partners__list li:nth-child(1),
.partners__list li:nth-child(7){
    border-left: none;
}
.partners__list li:nth-child(1),
.partners__list li:nth-child(2),
.partners__list li:nth-child(3),
.partners__list li:nth-child(4),
.partners__list li:nth-child(5),
.partners__list li:nth-child(6){
    border-top: none;
}
.partners__list li:nth-child(7),
.partners__list li:nth-child(8),
.partners__list li:nth-child(9),
.partners__list li:nth-child(10),
.partners__list li:nth-child(11),
.partners__list li:nth-child(12){
    border-bottom: none;
}

.partners__list li:nth-child(6),
.partners__list li:nth-child(12){
    border-right: none;
}

@media (min-width: 885px) {
    .partners__button{
        min-width: 137px;
    }
}
@media (max-width: 1440px) {
    .partners__list.is-active{
        grid-template-columns: repeat(4, 1fr);
    }

    .partners__list li:nth-child(4),
    .partners__list li:nth-child(8),
    .partners__list li:nth-child(12){
        border-right: none;
    }
    .partners__list li:nth-child(5),
    .partners__list li:nth-child(6){
        border-top: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(5),
    .partners__list li:nth-child(9){
        border-left: none;
    }
    .partners__list li:nth-child(6){
        border-right: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(7){
        border-left: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(7),
    .partners__list li:nth-child(8){
        border-bottom: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(9),
    .partners__list li:nth-child(10),
    .partners__list li:nth-child(11),
    .partners__list li:nth-child(12){
        border-bottom: none;
    }
}

@media (max-width: 855px) {
    .partners__title{
        font-size: 35px;
        line-height: 42px;
    }
    .partners__title span{
        font-size: 50px;
        line-height: 52px;
    }

    .partners__button {
        padding: 7px 23px;
    }
    .partners__button-padding{
        padding: 7px 33px;
        margin-right: 12px;
    }
    .partners__list li{
        height: 150px;
    }

}

@media (max-width: 767px) {
    .partners{
        margin-top: 50px;
        margin-bottom: 100px;
    }
    .partners__header{
        margin-bottom: 60px;
    }
    .partners__list.is-active{
        grid-template-columns: repeat(3, 1fr);
    }

    .partners__list li:nth-child(4){
        border-top: 1px solid #CBCBCB;
        border-left: none;
    }
    .partners__list li:nth-child(4),
    .partners__list li:nth-child(8){
        border-right: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(5),
    .partners__list li:nth-child(9){
        border-left: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(9){
        border-bottom: 1px solid #CBCBCB;
    }
    .partners__list li:nth-child(3),
    .partners__list li:nth-child(6),
    .partners__list li:nth-child(9){
        border-right: none;
    }
    .partners__list li:nth-child(7),
    .partners__list li:nth-child(10){
        border-left: none;
    }

    .partners__list li img{
        width: 90%;
        height: initial;
        padding: 0 30px;
    }

}

@media (max-width: 600px) {
    .partners__button{
        min-width: 93px;
    }
    .partners__header{
        display: flex;
        flex-direction: column;
    }
    .partners__buttons {
        margin-top: 21px;
    }
}

@media (max-width: 460px){
    .partners__button{
        min-width: 60px;
    }
    .partners{
        margin-top: 50px;
        margin-bottom: 43px;
    }
    .partners__header{
        margin-bottom: 0;
    }
    .partners__title{
        font-size: 25px;
        line-height: 28px;
    }
    .partners__title span{
        font-size: 35px;
        line-height: 38px;
    }
    .partners__buttons{
        display: flex;
        justify-content: space-between;
        margin-bottom: 27px;
    }
    .partners__buttons-right{
        display: flex;
        gap: 3px;
    }
    .partners__button {
        padding: 5px 12px;
        font-size: 11px;
    }
    .partners__button-padding{
        padding: 5px 20px;
        margin-right: 7px;
    }
    .partners__list.is-active{
        grid-template-columns: repeat(1, 1fr);
    }
    .partners__list li{
        height: 103px;
    }

    .partners__list li:nth-child(n+1):nth-child(-n+12){
        border: none;
    }
    .partners__list li:nth-child(n+3):nth-child(-n+12){
        display: none;
    }

    .container .partners__list li:nth-child(1),
    .container .partners__list li:nth-child(3),
    .container .partners__list li:nth-child(5),
    .container .partners__list li:nth-child(7),
    .container .partners__list li:nth-child(9),
    .container .partners__list li:nth-child(11){
        border-right: 1px solid #CBCBCB;
    }
}

@media (max-width: 450px) {
    .partners__list{
        display: flex;
    }
}