.buying__method {
    position: relative;
}

.buying__method::before {
    content: "";
    position: absolute;
    background-image: url("/public/img/buyingMethod.png");
    background-repeat: no-repeat;
    width: 83%;
    height: 1240px;
    top: -100px;
    right: 0;
}

.buying__container {
    height: 100%;
    max-width: 1700px;
}


.sidebar {
    background-color: #152E33;
    width: 747px;
    height: 983px;
    padding: 37px 0 93px 84px;
    position: relative;
    margin: 312px 0 255px 20px;
}

.sidebar::before {
    content: "";
    position: absolute;
    background-image: url("/public/img/triangle.png");
    background-size: cover;
    background-position: center;
    left: 0;
    width: 100%;
    height: 57px;
    top: -56px;
}

.buying__method-title p {
    margin: 0 0 0 42px;
    text-transform: uppercase;
}

.sidebar .buying__method-title span {
    color: #FFFFFF;
}

.buying__method-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.buying__button {
    background-color: transparent;
    border: 1px solid #CBCBCB;
    border-radius: 50px;
    font-family: "Montserrat", sans-serif;
    font-size: 27px;
    font-weight: 500;
    line-height: 37px;
    text-align: left;
    padding: 17px 98px;
    color: #E0BE74;
    cursor: pointer;
    transition: .3s;
}

.buying__button:hover {
    background-color: var(--mainColor);
    color: #152E33;
}

.buying__method .form {
    background-image: url("/public/img/apartmentSelection.png");
    background-size: cover;
    max-width: 747px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
    overflow: hidden;
    height: auto;
    max-height: 100%;
    overflow-y: scroll; /* Разрешаем вертикальный скроллинг */
    scrollbar-width: none; /* Firefox: убираем скроллбар */
}

.buying__method .form::-webkit-scrollbar {
    display: none; /* Скрываем скроллбар */
}

@media (max-height: 1270px) {
    .buying__method .form {
        clip-path: polygon(0 8%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 850px) {
    .buying__method .form {
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 650px) {
    .buying__method .form {
        clip-path: polygon(0 13%, 100% 0, 100% 100%, 0 100%);
    }
}

@media (max-height: 500px) {
    .buying__method .form {
        clip-path: polygon(0 16%, 100% 0, 100% 100%, 0 100%);
    }
}

.buying__method .form::-webkit-scrollbar {
    display: none; /* Для Chrome, Safari и других браузеров на основе WebKit */
}

.buying__method .form-label-row{
    margin-top: 15px;
}

.buying__method__formError {
    display: none;
    margin-top: 15px;
}

.form.active {
    display: block;
}

/* .no-scroll {
    overflow: hidden;
} */

.form-title-header-top-mobile {
    display: none;
}

.form-title-header-mobile {
    display: none;
}

@media(max-width: 1350px) {
    .buying__method .form {
        width: 100%;
    }
}

@media(max-width: 1200px) {

    .sidebar {
        padding: 37px 0 37px 20px;
    }

    .buying__method::before {
        display: none;
    }

    .buying__container {
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
    }

    .sidebar {
        width: 100%;
        margin: 80px 0 0;
        background-color: rgba(0, 0, 0, 0);
        background-image: url("/public/img/buying-method-bg-mobile.png");
        background-size: cover;
    }

    .sidebar::before {
        display: none;
    }

    .sidebar .buying__method-title {
        padding-top: 50px;
        margin-bottom: 50px;
    }

    .buying__method-list {
        gap: 27px;
    }

    .buying__method-title {
        padding-left: 9%;
    }

    .buying__method-title p {
        text-align: center;
    }

    .buying__method-list {
        text-align: center;
    }

    .buying__button {
        width: 65%;
        text-align: center;
    }
}

@media(max-width: 1019px) {

    .buying__button {
        width: 80%;
    }
}

@media(max-width: 800px) {
    .buying__method .form {
        width: 80%;
    }

    .buying__method .form__container {
        padding: 40px;
    }

    .buying__method .form-title h2 {
        font-size: 32px;
        line-height: normal;
    }

    .buying__method .form-title p {
        font-size: 22px;
        padding-bottom: 40px;
    }

    .form-label-flex-column {
        flex-direction: column;
    }

    .form-label-flex-column .form-label-input-wrapper {
        justify-content: start;
    }

    .form-label-flex-column .form-label-input::before {
        width: 75%;
    }

    .form-label-flex-column .form-label-input2-first::before {
        width: 65%;
    }

    .form-label-flex-column .form-label-input span {
        justify-content: start;
    }
}

@media(max-width: 768px) {
    .sidebar .buying__method-title {
        margin-bottom: 23px;
    }

    .buying__method .form-title h2 {
        padding-top: 75px;
    }

    .buying__method .form-title p {
        padding-bottom: 25px;
    }

    .buying__method-list {
        gap: 15px;
    }

    .buying__method .form-label-befor-block .form-label-input span::before {
        display: block;
    }

    .buying__method .form .form__button {
        height: 75px;
        margin-top: 0px;
        font-size: 12px;
    }

}

@media(max-width: 600px) and (min-width: 555px) {
    .sidebar {
        height: 710px;
    }

    .buying__method .form-label-flex-column .form-label-input2-first::before {
        width: 60%;
    }
}

@media(max-width: 554px) {
    .sidebar {
        height: 790px;
    }

    .buying__method .form__container {
        padding: 20px;
    }

    .highlight-word {
        font-size: 32px;
        font-weight: 700;
        color: var(--mainColor);
    }

    .buying__method .form-label-select-content {
        background-size: 15px;
    }

    .buying__method .form-title h2 {
        display: flex;
        flex-direction: column;
        font-size: 22px;
        line-height: normal;
    }

    .buying__method .form-title p {
        font-size: 12px;
        padding-bottom: 28px;
        line-height: 15px;
    }

    .buying__method .form-label-title {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .buying__method .form-label-flex {
        gap: 6px;
    }

    .buying__method .form-label-flex .form-label-input span,
    .buying__method .form-label-text input,
    .buying__method .form-label-text input::placeholder,
    .buying__method .form-label-select-content {
        font-size: 11px;
    }

    .buying__method .add-small-margin {
        padding-top: 16px;
    }

    .buying__method .form .form__button {
        max-height: 52px;
        font-weight: 600;
        font-size: 8px;
        margin-top: 0;
    }

    .buying__method .form-label-politic {
        margin-top: 20px;
    }

    .buying__method .form-label-politic span {
        font-size: 9px;
    }

    .buying__method .form-label-befor-block .form-label-input span::before {
        width: 12px;
        height: 12px;
    }
}

@media(max-width: 600px) {

    .sidebar .buying__method-title {
        font-size: 55px;
    }

    .sidebar .buying__method-title span {
        font-size: 40px;
    }

    .buying__button {
        padding: 6px 75px;
        font-size: 20px;
    }
}


@media(max-width: 450px) {
    .sidebar {
        height: auto;
        padding: 20px 0 37px 0;
        margin: 0;
    }

    .sidebar .buying__method-title {
        font-size: 35px;
    }

    .sidebar .buying__method-title span {
        font-size: 25px;
    }

    .buying__button {
        width: 66%;
        font-size: 10px;
        font-size: 15px;
        line-height: 14px;
        padding: 6px 10px;
    }

    .buying__method .form-label-flex-column .form-label-input::before {
        width: 90%;
    }

    .buying__method .form-label-flex-column .form-label-input2-first::before {
        width: 65%;
    }
}

@media(max-width: 395px) {
    .buying__method .form-label-flex-column .form-label-input::before {
        width: 100%;
    }

    .buying__method .form-label-flex-column .form-label-input2-first::before {
        width: 75%;
    }
}

@media(max-width: 345px) {
    .buying__method .form-label-flex-column .form-label-input::before {
        width: 95%;
    }

    .buying__method .form-label-flex-column .form-label-input2-first::before {
        width: 80%;
    }
}