@use '../../components/styles/mixins.scss' as *;

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-family: 'Arial', sans-serif;
  margin: 0 auto;

  @include mobile {
    padding-left: 10px;
  }

  &__input {
    display: none;
    /* Скрываем стандартный checkbox */
  }

  &__box {
    width: 17px;
    height: 17px;
    border: 1px solid #8A8A8A;
    border-radius: 55px;
    background-color: #fff;
    position: relative;
    transition: all 0.3s ease;

    & img {
      position: absolute;
      top: 4px;
      left: 3px;
    }
  }

  &__label {
    font-family: 'PT Sans Caption', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #8A8A8A;
    margin-left: 11px;
  max-width: 188px;
    @include mobile {
      font-size: 10px;
      margin-left: 7px;
    }
  }
}