@use '../../components/styles/mixins.scss' as *;

.similar-offer {
  &__wrappper {
    @include container;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 100px;
    @media (max-width: 996px){
      margin-bottom: 50px;
    }

    @include mobile {
      margin-bottom: 35px;
    }

    h2 {
      font-size: 44px;
      font-weight: 400;
      line-height: 53px;
      margin-bottom: 50px;

      @media (max-width: 996px){
        margin-bottom: 30px;
      }
      @media (max-width: 767px){
        margin-bottom: 0;
      }
      @include tablet {
        font-size: 35px;
        line-height: 1.2;
      }

      @include mobile {
        font-size: 25px;
        line-height: 30px;
      }
    }

    @media (max-width: 1024px) {
      .apartment {
        flex: 1 1 100%;
      }
    }
  }

  &__header {
    @include mobile {
      display: flex;
      justify-content: space-between;
    }

    svg {
      display: none;
      width: 24px;
      height: 24px;
      cursor: pointer;

      @include mobile {
        display: block;
        margin-top: auto;
      }
    }

  }

  &__list {
    display: flex;
    gap: 40px;
    list-style-type: none;
    margin-bottom: 73px;
    flex-wrap: wrap;
    @media (max-width: 996px) {
      gap: 30px 34px;
    }
    @include mobile {
      display: none;
      gap: 20px 24px;
      margin-bottom: 30px;
    }

    &.visible {
      @include mobile {
        display: flex;
        margin-top: 50px;
      }
    }
  }

    &__item {
        font-size: 21px;
        font-weight: 400;
        line-height: 33px;
        padding-bottom: 15px;
        padding-left: 7px;
        color: #141414;
        border-bottom: 1px solid #CBCBCB;
        cursor: pointer;

        &.active {
            border-bottom: 1px solid #E0BE74;
        }

    @include tablet {
      font-size: 18px;
      line-height: 25px;
      padding-bottom: 12px;
    }

    @include mobile {
      font-size: 12px;
      line-height: 20px;
      padding-left: 15px;
      padding-bottom: 8px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px 34px;

    @media(max-width: 1550px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(max-width: 830px) {
      grid-template-columns: 1fr;
    }
  }

  &__slider-buttons {
    display: none;
    margin-left: auto;

    @media(max-width: 831px) {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    }

    @media(max-width: 450px) {
      gap: 5px;
    }
  }
}