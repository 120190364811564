.apartment {
  position: relative;
  background: #FFF;
  padding: 72px 30px 20px;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  overflow: hidden;
  transition: .3s;
  &:hover{
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.8);
  }
  @media (max-width: 1024px) {
    padding: 50px 20px 20px;
  }
  @media (max-width: 768px) {
    padding: 25px 0 30px 0;
  }
  @media (max-width: 520px) {
    padding: 18px 0 25px 0;
  }

  &__favorites {
    cursor: pointer;
    position: absolute;
    left: 20px;
    top: 20px;
    width: 31px;
    border: none;
    background: none;
    height: 28px;
    @media (max-width: 1024px) {
      left: 15px;
      top: 15px;
      width: 25px;
      height: 22px;
    }
    @media (max-width: 520px) {
      left: 10px;
      top: 10px;
      width: 16px;
      height: 15px;
    }

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  &__compare {
    cursor: pointer;
    position: absolute;
    right: 20px;
    border: none;
    background: none;
    top: 20px;
    width: 36px;
    height: 33px;
    @media (max-width: 1024px) {
      right: 15px;
      top: 15px;
      width: 25px;
      height: 22px;
    }
    @media (max-width: 520px) {
      right: 10px;
      top: 10px;
      width: 19px;
      height: 17px;
    }

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  &__block-image {
    width: 100%;
    margin-bottom: 23px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
    @media (max-width: 520px) {
      margin-bottom: 10px;
      padding-top: 15px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 339px;
      padding: 10px;
      @media (max-width: 996px) {
        height: 280px;
      }
      @media (max-width: 768px) {
        height: 260px;
      }
      @media (max-width: 520px) {
        height: 221px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    @media (max-width: 768px) {
      gap: 10px;
      padding: 0 16px;
    }
    @media (max-width: 520px) {
      gap: 8px;
      padding: 0 13px;
    }
  }

  &__title {
    color: #000;
    font-size: 28px;
    font-weight: 300;
    line-height: 100%;

    @media (max-width: 996px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 18px;

    }
    @media (max-width: 520px) {
      font-size: 15px;
      padding-left: 3px;
    }
  }

  &__price {
    color: #000;
    font-size: 56px;
    font-weight: 400;
    line-height: 89%;
    white-space: nowrap;
    @media (max-width: 1485px) {
      font-size: 52px;
    }
    @media (max-width: 1400px) {
      font-size: 46px;
    }
    @media (max-width: 1280px) {
      font-size: 44px;
    }
    @media (max-width: 768px) {
      font-size: 36px;
    }
    @media (max-width: 520px) {
      font-size: 29px;
      padding-left: 3px;
    }
  }

  &__meter {
    color: #6B6B6B;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 520px) {
      font-size: 11px;
      margin-bottom: 3px;
      padding-left: 3px;
    }
  }

  &__info {
    margin-bottom: 35px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media (max-width: 520px) {
      margin-bottom: 17px;
      padding-left: 3px;
    }
  }

  &__btns {
    padding: 0 20px 0 10px;
    display: flex;
    gap: 10px 12px;
    flex-wrap: wrap;
    width: 100%;

    @media (max-width: 996px) {
      padding: 0;
    }

    a, button {
      flex: 1 1 calc(50% - 12px);
      @media (max-width: 1380px) and (min-width: 1240px) {
        flex: 1 1 100%;
      }
      @media (max-width: 520px) {
        flex: 1 1 100%;
      }
    }
  }

  &__more {
    color: #FFF;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    border-radius: 99px;
    background: #123539;
    padding: 20px;
    @media (max-width: 996px) {
      padding: 19px 14px;
    }
    @media (max-width: 520px) {
      font-size: 13px;
      padding: 15.5px;
    }
  }

  &__select {
    padding: 20px;
    color: #141414;
    text-align: center;
    cursor: pointer;
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    border-radius: 99px;
    border: 1px solid #123539;
    line-height: normal;
    @media (max-width: 996px) {
      padding: 19px 14px;
    }
    @media (max-width: 520px) {
      padding: 14.5px;
      font-size: 13px;
    }
  }
}

.info-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 768px) {
    gap: 4px;
  }
  @media (max-width: 520px) {
    gap: 3px;
  }

  &__item {
    display: flex;
    gap: 10px;
    color: #273E42;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
    @media (max-width: 996px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      gap: 5px;
      font-size: 15px;
    }
    @media (max-width: 520px) {
      gap: 2px;
      font-size: 12px;
    }
  }

  &__name {

  }

  &__value {
  }
}