.apartments {
  .partners__button {
    @media (max-width: 520px) {
      min-width: 108px;
      padding: 7px 20px;
    }
  }

  &__info {
    display: flex;
    gap: 84px;
    align-items: center;
    margin-bottom: 50px;
    @media (max-width: 996px) {
      margin-bottom: 40px;
    }
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 10px;
      align-items: flex-start;
      margin-bottom: 30px;
    }
    @media (max-width: 520px) {
      margin-bottom: 20px;
    }

    .form-label-select-content {
      padding: 0;
      font-size: 28px;
      @media (max-width: 1280px) {
        font-size: 22px;
      }
      @media (max-width: 996px) {
        font-size: 19px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
      }
      @media (max-width: 520px) {
        font-size: 12px;
      }
    }

    .filters__list{
      flex-direction: column;

      li{

        &:not(:last-child){
          border-bottom: 1px solid #E0BE74;
        }
        button{
          text-align: left;
          padding: 13px 20px;
          cursor: pointer;
          width: 100%;
          font-size: 18px;
          line-height: 1.3;
          @media (max-width: 996px){
            font-size: 16px;
          }
          @media (max-width: 768px){
            font-size: 14px;
            padding: 10px 12px;
          }
          @media (max-width: 520px){
            font-size: 11px;
            padding: 8px 10px;
          }
        }
      }
    }


  }

  &__search {
    color: #000;
    font-size: 28px;
    font-weight: 300;
    line-height: 100%;
    @media (max-width: 1240px) {
      font-size: 20px;
    }
    @media (max-width: 996px) {
      font-size: 18px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 520px) {
      font-size: 13px;
    }
  }

  &__sort {
    position: relative;
    @media (max-width: 768px) {
      padding-left: 22px;
    }

    &::after {
      content: '';
      background: url("../../../../../public/img/svg/sort.svg") 0 0 / cover no-repeat;
      width: 16px;
      height: 12px;
      position: absolute;
      left: -22px;
      top: 0;
      bottom: 0;
      margin: auto;
      @media (max-width: 768px) {
        left: 0px;
      }
    }
  }

  .tabs {
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 25px;
    }
    @media (max-width: 520px) {
      margin-bottom: 20px;
    }
  }
}

.apartment__btn-more{
  margin-top: -160px;
  @media (max-width: 768px){
    margin-top: -80px;
  }
}

.apartment__btn-more_layout{
  margin-top: -80px;
}