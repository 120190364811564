.apartment-selection {
    position: relative;
    padding-top: 87px;
}

.apartment-selection::before {
    content: "";
    position: absolute;
    background-image: url("/public/img/apartment-selectionBG.png");
    background-repeat: no-repeat;
    width: 56%;
    height: 87.8%;
    top: 164px;
    right: 0;
    z-index: -1;
}

.apartment-selection .form {
    background-image: url("/public/img/apartmentSelection.png");
    max-width: 747px;
    max-height: 1294px;
}

.apartment-selection__formError {
    display: none;
    margin-top: 27px;
}

@media(max-width:1200px) {
    .apartment-selection::before {
        display: none;
    }

    .apartment-selection .form {
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width:768px) {
    .apartment-selection .form__container {
        padding: 70px 40px;
    }

    .apartment-selection .form-title h2 {
        font-size: 35px;
        padding-top: 40px;
        text-transform: uppercase;
        line-height: 35px;
    }

    .apartment-selection .form-title span {
        color: var(--mainColor);
        font-size: 44px;
        font-weight: 700;
    }

    .apartment-selection .form-title p {
        font-size: 22px;
        padding-bottom: 50px;
    }

    .apartment-selection .form .button {
        height: 70px;
        font-size: 13px;
    }
}

@media(max-width:555px) {
    .form-single .form .button{
        height: 52px;
    }
    .apartment-selection .form-label-flex-column .form-label-input::before {
        width: 90%;
    }

    .apartment-selection .form-label-flex-column .form-label-input-special::before {
        width: 70%;
    }

    .apartment-selection .apartment-selection__formError{
        margin-bottom: 0;
    }
}

@media(max-width:450px) {
    .apartment-selection {
        padding-top: 0;
    }

    .apartment-selection .form__container {
        padding: 73px 20px 10px;
    }

    .apartment-selection .form-title h2 {
        font-size: 23px;
        padding-top: 20px;
        text-transform: uppercase;
        line-height: 23px;
    }

    .apartment-selection .form-title span {
        color: var(--mainColor);
        font-size: 32px;
        font-weight: 700;
        line-height: 35px;
    }

    .apartment-selection .form-title p {
        font-size: 13px;
        padding-bottom: 28px;
        line-height: 16px
    }

    .form-label-flex-column .form-label-input::before {
        width: 65%;
    }

    .form-label-flex-column .form-label-input-special::before {
        width: 55%;
    }

    .apartment-selection .form-label-flex {
        gap: 6px;
    }

    .apartment-selection__formError {
        margin-top: 10px;
    }

    .apartment-selection .form-label-flex-column .form-label-input::before {
        width: 70%;
    }

    .apartment-selection .form-label-flex-column .form-label-input-special::before {
        width: 50%;
    }

    .apartment-selection .form .button {
        margin-top: 15px;
        height: 52px;
        font-size: 9px;
        font-weight: 600;
    }
}

@media(max-width:375px) {
    .apartment-selection .form-label-flex-column .form-label-input::before {
        width: 75%;
    }

    .apartment-selection .form-label-flex-column .form-label-input-special::before {
        width: 55%;
    }
}