.about {
    padding: 0 0 120px 0;
}

.about-body {
    display: flex;
    justify-content: space-between;
    margin-bottom: 140px;
}

.about-body h2 img {
    max-width: 400px;
}

.about-body h2 p {
    color: #141414;
    font-size: 54px;
    line-height: 58px;
    font-weight: 300;
}

.about-text-block {
    position: relative;
    max-width: 525px;
    display: flex;
    flex-direction: column;
    gap: 50px;

}

.about-text-block:nth-of-type(1) {
    margin-left: -380px;
    margin-top: 240px;
}

.about-text-block:nth-of-type(2) {
    margin-top: 80px;
    margin-right: 100px;
}

.about-text-title {
    position: relative;
    font-size: 33px;
    font-weight: 700;
    color: var(--mainColor);
}

.about__animate {
    width: 144px;
    height: 150px;
    position: absolute;
    top: -44px;
    left: -42px;
    overflow: visible;
}


.about-text {
    font-size: 27px;
    color: #141414;
}

.about-numbers {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.about-numbers p {
    display: flex;
    flex-direction: column;
    font-size: 110px;
    color: #273F43;
}

.about-numbers span {
    font-size: 22px;
    color: #999999;
}

@media (max-width: 1410px) {
    .about-text-block:nth-of-type(2) {
        margin-right: 0px;
    }
}

@media (max-width: 1370px) {
    .about-text-block:nth-of-type(1) {
        margin-left: -320px;
        margin-top: 147px;
    }
}

@media (max-width: 1240px) {
    .about {
        margin-top: -140px;
    }

    .about-body {
        flex-direction: column;
    }

    .about-text-block:nth-of-type(1) {
        margin-left: 0;
        margin-top: 50px;
        align-self: end;
    }

    .about-text-block:nth-of-type(2) {
        margin-right: 0px;
        margin-top: -65px;
        margin-left: 60px;
    }

    .about-numbers p {
        font-size: 90px;
    }
}

@media (max-width: 1080px) {
    .about {
        margin-top: 80px;
    }

    .about-text-block:nth-of-type(2) {
        margin-top: 0px;
    }
}

@media (max-width: 900px) {
    .about-numbers {
        flex-wrap: wrap;
        gap: 50px;
    }

    .about-text-block:nth-of-type(1) {
        margin-top: 80px;
    }

    .about-text-block:nth-of-type(2) {
        margin-top: 100px;
    }
}

@media (max-width: 768px) {
    .about-body h2 p {
        font-size: 40px;
        line-height: 45px;
    }

    .about-body h2 img {
        max-width: 310px;
    }

    .about-text-block {
        gap: 20px;
    }

    .about-text-title {
        margin-bottom: 30px;
    }

    .about-text {
        font-size: 23px;
    }
}

@media (max-width: 630px) {
    .about {
        padding: 0;
    }

    .about-body {
        margin-bottom: 60px;
    }

    .about-text-title {
        font-size: 25px;
    }

    .about-text {
        font-size: 19px;
    }

    .about-text-title {
        margin-bottom: 15px;
    }

    .about-text-block:nth-of-type(1) {
        margin-left: 120px;
        margin-top: 50px;
    }

    .about-text-block:nth-of-type(2) {
        margin-left: 40px;
        margin-top: 70px;
    }

    .about-text-title::before {
        top: -50px;
        left: -95px;
    }
}

@media(min-width: 1081px) and (max-width: 1240px) {
    .about {
        margin-top: 50px;
    }
}

@media (max-width: 500px) {
    .about-numbers p {
        font-size: 64px;
    }

    .about-numbers span {
        font-size: 18px;
    }
}

@media (max-width: 450px) {
    .about {
        margin-top: 0;
    }
}

@media (max-width: 390px) {
    .about-body h2 img {
        max-width: 210px;
        margin-bottom: 14px;
    }

    .about-body h2 p {
        font-size: 25px;
        line-height: 25px;
    }

    .about-text-title {
        font-size: 19px;
    }

    .about-text {
        font-size: 14px;
    }

    .about-text-title::before {
        background-size: auto;
    }

    .about-text-block:nth-of-type(1) {
        margin-left: 120px;
    }

    .about-text-block:nth-of-type(2) {
        margin-left: 70px;
        margin-top: 50px;
    }

    .about-numbers p {
        font-size: 44px;
    }

    .about-numbers span {
        font-size: 14px;
    }

    .about-body {
        margin-bottom: 60px;
    }
}