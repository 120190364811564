.single-info{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1 1 auto;
  padding-left: 26px;
  padding-top: 19px;
  @media (max-width: 996px){
    padding-top: 14px;
    padding-left: 20px;
    gap: 9px;
  }
  @media (max-width: 768px){
    padding-top: 12px;
    padding-left: 18px;
    gap: 7px;
  }
  @media (max-width: 520px){
    padding-top: 10px;
    padding-left: 15px;
    gap: 5px;
  }
  &__title{
    position: relative;
    color: #123539;
    font-size: 40px;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 996px){
      font-size: 24px;
    }
    @media (max-width: 768px){
      font-size: 22px;
    }
    @media (max-width: 520px){
      font-size: 18px;
    }
  }
  &__desc{
    color: #999;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 996px){
      font-size: 17px;
    }
    @media (max-width: 768px){
      font-size: 14px;
    }
    @media (max-width: 520px){
      font-size: 10px;
    }
  }

  svg{
    @media (max-width: 1240px){
      width: 130px;
      height: 133px;
      position: absolute;
      top: -35px;
      left: -44px;
    }
    @media (max-width: 768px){
      width: 127px;
      height: 124px;
      position: absolute;
      top: -29px;
      left: -16px;
    }
    @media (max-width: 520px){
      width: 95px;
      height: 90px;
      position: absolute;
      top: -19px;
      left: -15px;
    }

  }
}