.mapCard {
  max-width: 770px;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  min-width: 300px;

  @media (max-width: 520px){
      min-width: 250px;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  a {
    display: block;
    width: 100%;
  }

  &__title {
    color: #123539;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    color: #123539;
  }

  p {
    color: #141414;
  }

  &__price {
    text-align: left;
    color: #141414;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 10px 10px;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    max-height: 200px;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 10px;
  }

  &__location {
    width: fit-content;
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
  }

  &__link {
    display: none;
  }
}

.mapCard--accent {
  border-color: rgba(18, 53, 57, 1);

  .mapCard__link {
    background-color: rgba(18, 53, 57, 1);
    color: #123539;
  }

  .mapCard__button {
    color: #123539;
    background-color: rgba(224, 190, 116, 1);
  }
}
.ymaps-2-1-79-balloon__close{
  position: absolute !important;
  right: 5px;
  border: 1px solid #E0BE74;
  background: #fff;
  border-radius: 50%;
  transform: scale(0.8);
}
.ymaps-2-1-79-balloon__close-button{
  width: 30px !important;
  height: 30px !important;
}
.ymaps-2-1-79-balloon__content{
  margin-right: 12px !important;
}
