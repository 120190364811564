@import './variables.scss';

@mixin container() {
    // max-width: 1710px;
    margin: 0 auto;
    padding: 0 106px;

    @include tablet {
        padding: 0 75px;
    }

    @include mobile {
        padding: 0 27px;
    }
}

@mixin laptop() {
    @media(max-width: ($laptop - 1px)) {
        @content;
    }
}

@mixin tablet() {
    @media(max-width: ($tablet - 1px)) {
        @content;
    }
}

@mixin mobile() {
    @media(max-width: ($mobile - 1px)) {
        @content;
    }
}