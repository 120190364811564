.filters {
  &__attr {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__types {
    display: flex;
    border: 1px solid #E0BE74;
    background: #F9F9F9;
    width: max-content;
    @media (max-width: 1280px){
        flex: 1 1 100%;
      justify-content: space-between;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__search {
    color: #273E42;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    line-height: normal;
    border-radius: 79px;
    background: #E0BE74;
    padding: 28px 77px;
    border: none;
    width: max-content;
    margin-left: auto;
    @media (max-width: 996px) {
      padding: 22px 75px;
      font-size: 17px;
    }
    @media (max-width: 768px) {
      padding: 20px 70px;
      font-size: 15px;
      width: 100%;
    }
    @media (max-width: 520px) {
      padding: 17px 32px;
      font-size: 13px;
    }
  }

  &__list {
    display: flex;
    position: absolute;
    border: 1px solid #E0BE74;
    background: #FFF;
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    width: 100%;
    z-index: 52;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    li {
      list-style: none;
      flex: 1 1 50%;

      .form-label-input {
        &::before {
          content: none;
        }
      }

      .form-label-input-wrapper {
        justify-content: flex-start;
        align-items: flex-start;
        @media (max-width: 768px){
          padding-left: 30px;
        }
        @media (max-width: 520px){
          padding-left: 20px;
        }
      }

      &:not(:first-child) {
        &:last-child {
          position: relative;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            left: -1px;
            width: 1px;
            height: 70%;
            background: #273E42;
            margin: auto;
          }
        }
      }

      input {
        border: none;
        padding: 23px 20px;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 100%;
        outline: none;
        @media (max-width: 996px) {
          font-size: 17px;
          padding: 13px 20px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          padding: 13px 20px;
        }
        @media (max-width: 520px) {
          font-size: 11px;
          padding: 13px 20px;
        }

        &::placeholder {
          color: #273E42;
        }
      }

      .form-label-input span {
        color: #273E42;
        font-size: 18px;
        font-weight: 400;
        line-height: 130%;
        gap: 10px;
        @media (max-width: 996px){
          font-size: 14px;
        }
        @media (max-width: 768px){
          font-size: 12px;
        }
        @media (max-width: 520px){
          font-size: 10px;
        }
      }

      .form-label-input-wrapper input[type="checkbox"]:checked + .form-label-input, .form-label-input-wrapper input[type="radio"]:checked + .form-label-input {
        color: inherit;
      }

      label {
        padding-left: 40px;
      }

      .form-label-input span::before, .form-label-politic span::before {
        margin-top: -4px;
        border: 1px solid #152E33;
        border-radius: 2px;
        @media (max-width: 996px){
          width: 14px;
          height: 14px;
          margin-top: -1px;
        }
        @media (max-width: 768px){
          width: 12px;
          height: 12px;
          content: '' !important;
          display: block !important;
        }
        @media (max-width: 520px){
          width: 9px;
          height: 9px;
        }
      }
    }
  }
  &__mobile-top{
    border: none;
    background: transparent;
  }
}


.filters__list {

}
