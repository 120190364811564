.filters {
  margin-bottom: 100px;
  & + .feedBackRound__wrapper{
    padding: 0;
    margin-bottom: 150px;
    @media (max-width: 996px) {
      margin-bottom: 85px;
    }
    @media (max-width: 768px) {
      margin-bottom: 70px;
    }
    @media (max-width: 520px) {
      margin-bottom: 50px;
    }
  }
  @media (max-width: 996px) {
    margin-bottom: 85px;
  }
  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
  @media (max-width: 520px) {
    margin-bottom: 50px;
  }

  &__dropdown {
    position: relative;
    width: max-content;

    &:nth-last-child(n+3) {
      flex: 1 1 auto;
      @media (max-width: 520px){
        width: 100%;
      }
    }
    
    @media (max-width: 996px) {
      flex: 1 1 auto;
    }
    @media (max-width: 768px){
        &:nth-last-child(-n+2){
          width: calc(50% - 12px);
        }
    }
    @media (max-width: 520px){
        &:first-child{
          .filters__list {
            flex-direction: column;
            li{
              &:not(:last-child){
                border-bottom: 1px solid #E0BE74;
              }
            }
            button{
              padding: 13px 20px;
              width: 100%;
              text-align: left;
            }
          }
        }
    }

  }

  &__drop-item {
    position: relative;
    cursor: pointer;
    color: #273E42;
    text-align: left;
    font-size: 22px;
    font-weight: 400;
    line-height: 130%;
    border: 1px solid #E0BE74;
    padding: 23px 196px 22px 45px;
    background: transparent;
    width: 100%;
    b{

    }
    span{
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: max-content;
      font-size: 20px;
      padding-left: 5px;
      font-weight: 500;
      @media (max-width: 996px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        font-size: 12px;
      }
      @media (max-width: 520px) {
        font-size: 9px;
      }
    }
    @media (max-width: 1024px) {
      width: 100%;
      font-size: 19px;
    }
    @media (max-width: 996px) {
      font-size: 15px;
      padding: 18px 140px 22px 35px;
    }
    @media (max-width: 768px) {
      font-size: 15px;
      padding: 16px 80px 18px 30px;
    }
    @media (max-width: 520px) {
      padding: 13px 20px;
      font-size: 11px;
    }

    &::after {
      content: '';
      background: url("../../../../../public/img/svg/drop-arrow.svg") 0 0 / cover no-repeat;
      position: absolute;
      right: 45px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 16px;
      height: 9px;
      transition: .3s;
      @media (max-width: 996px) {
        width: 9px;
        height: 6px;
        right: 20px;
      }
      @media (max-width: 768px) {
        width: 9px;
        height: 6px;
        right: 20px;
      }
      @media (max-width: 520px) {
        width: 9px;
        height: 6px;
        right: 20px;
      }
    }

    &.active {
      &::after {
        transform: rotate(180deg);
      }
    }

  }
}