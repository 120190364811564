.reviews{
    margin: 177px 0 148px;
}

.reviews__header{
    display: flex;
    justify-content: space-between;
}

.reviews__title{
    text-transform: uppercase;
}

.reviews__slider .slick-track {
    display: flex !important;
    align-items: flex-start;
}

.reviews__title span{
    text-transform: lowercase;
    margin-left: 143px;
}

.reviews__rating {
    display: flex;
    align-items: center;
}

.rating__link {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: 14px;
}

.stars {
    color: black;
    font-size: 40px;
    margin-right: 25px;
    margin-left: 30px;
}

.rating__text {
    font-size: 30px;
    color: #141414;
    margin-top: 10px;
}

.reviews__buttons {
    margin-left: auto;
}

.reviews__button{
    width: 70px;
    height: 70px;
    color: var(--mainColor);
    border: 1px solid rgba(18, 53, 57, 1);
    background: none;
    border-radius: 99px;
    font-size: 27px;
    cursor: pointer;
}

.reviews__button:nth-child(2){
    margin-left: 12px;
}

.review{
    max-width: 514px;
    padding: 0 20px 53px;
    border-bottom: 2px solid var(--mainColor);
}

.review__header{
    display: flex;
    padding-bottom: 60px;
    align-items: center;
}

.review__person-img{
    width: 78px;
    height: 78px;
    background-color: var(--mainColor);
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #FFFFFF;
}

.review__person-info{
    padding-left: 33px;
    color: #141414;
    font-size: 22px;
    font-weight: 500;

}

.reviews__slider {
    display: flex;
    gap: 100px;
    list-style: none;
}

.reviews__slider .slick-slide {
    margin: 0 15px;
}

.review__content{
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 29px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
}

.review__content.expanded {
    -webkit-line-clamp: unset;
}

.review__full{
    cursor: pointer;
    padding-bottom: 53px;
    font-size: 21px;
    color: #152E33;
    font-weight: 600;
}

.review__full span{
    color: var(--mainColor);
}

@media (max-width:1052px){
    .reviews .reviews__title{
        margin-bottom: 0;
    }
    .rating__link {
        margin-bottom: 80px;
    }
    .reviews__rating {
        flex-direction: column;
        align-items: flex-start;
    }
    .stars {
        margin-left: 143px;
    }

    .review:nth-child(3){
        display: none;
    }
}

@media (max-width:768px){
    .reviews {
        margin: 80px 0 0;
    }
    .reviews .reviews__title{
        font-size: 50px;
    }
    .reviews .reviews__title span{
        font-size: 37px;
    }
    .review {
        max-width: 620px;
    }
    .rating__link {
        margin-bottom: 40px;
    }
    .stars {
        font-size: 32px;
    }
    .rating__text {
        font-size: 21px;
        margin-top: 0;
    }
    .reviews__button {
        width: 50px;
        height: 50px;
        font-size: 27px;
    }
    .reviews__slider {
        gap: 10px;
    }
    .review__header {
        padding-bottom: 40px;
    }
    .review__person-img {
        width: 65px;
        height: 65px;
    }
    .review__person-info {
        font-size: 18px;
    }
    .review__content{
        font-size: 18px;
    }
    .review__full {
        padding-bottom: 38px;
        font-size: 18px;
    }
}
@media (max-width:690px){
    .reviews__header {
        justify-content: flex-start;
    }
    .stars {
        margin-right: 15px;
    }
    .rewiews__buttons{
        margin-top: 80px;
        margin-left: 20px;
    }
    .reviews__button {
        color: #123539;
        border: 1px solid rgba(18, 53, 57, 1);
        font-size: 24px;
    }
    .reviews__button:nth-child(2) {
        margin-left: 5px;
    }
    .reviews__slider {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }
    .review:nth-child(2){
        display: none;
    }
}

@media (max-width:572px){
    .review {
        padding-bottom: 22px;
    }
    .reviews .reviews__title{
        font-size: 35px;
    }
    .reviews .reviews__title span{
        font-size: 25px;
        margin-left: 43px;
    }
    .rating__link {
        margin-bottom: 23px;
        margin-top: 0;
    }
    .stars {
        font-size: 22px;
        margin-left: 43px;
        margin-right: 10px;
    }
    .rating__text {
        font-size: 12px;
        margin-top: 0;
    }
    .rewiews__buttons {
        margin-top: 57px;
    }
    .reviews__button {
        width: 25px;
        height: 25px;
        font-size: 16px;
    }
    .reviews__slider {
        grid-template-columns: repeat(1, 0.7fr);
    }
    .reviews__slider {
        gap: 0;
    }
    .review__header {
        padding-bottom: 26px;
    }
    .review__person-img {
        width: 52px;
        height: 52px;
    }
    .review__person-info {
        font-size: 14px;
        padding-left: 19px;
    }
    .review__content{
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 30px;
    }
    .review__full {
        padding-bottom: 22px;
        font-size: 14px;
    }
}
@media (max-width:450px){
    .reviews {
        margin: 50px 0 0;
    }

    .reviews__slider {
        grid-template-columns: repeat(1, 0.9fr);
    }
}

@media (max-width: 420px) {
    .reviews__slider {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 330px) {
    .stars {
        margin-left: 23px;
    }
    .reviews .reviews__title span{
        margin-left: 23px;
    }
}