.single__attribute-item {
  padding: 26px 0;
  font-size: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.50);
  @media (max-width: 1280px) {
    padding: 24px 0 18px;
    font-size: 18px;
  }
  @media (max-width: 996px) {
    padding: 22px 0 15px;
    font-size: 16px;
  }
  @media (max-width: 768px) {
    padding: 18px 0 12px;
    font-size: 14px;
  }
  @media (max-width: 520px) {
    padding: 15px 0 9px;
    font-size: 12px;
  }
}

.item-attribute{
  display: flex;
  justify-content: space-between;
  gap: 4px;
  border-top: 1px solid #BDBDBD;
  border-bottom: 1px solid #BDBDBD;
  &__value{
    color: #141414;
  }

}