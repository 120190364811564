.share {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #273E42;
  font-size: 12px;
  font-weight: 400;
  border-radius: 22px;
  border: 1px solid #CBCBCB;
  padding: 8px 11px;
  margin-bottom: 20px;
  cursor: pointer;
  @media (max-width: 759px) {
    padding: 6px 12px;
    margin-bottom: 16px;
    font-size: 10px;
  }
  @media (max-width: 520px){
    font-size: 9px;
  }

  svg {
    margin-right: 10px;
    @media (max-width: 759px) {
      margin-right: 7px;
      width: 19px;
      height: 19px;
    }
    @media (max-width: 520px){
      width: 17px;
      height: 17px;
    }
  }

}