.exclusive .container {
    margin-top: 59px;
    padding-bottom: 402px;
}

.exclusive-title {
    text-align: center;
    position: relative;
    left: -250px;
    text-transform: uppercase;
}

.exclusive-title span {
    margin-top: -20px;
    right: -60%;
    position: relative;
    text-transform: lowercase;
    width: fit-content;
}

.exclusive-list__wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    margin-bottom: 130px;
}

.exclusive-list__wrapper:last-child{
    margin-bottom: 0;
}

.exclusive-item {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: 700px;
    background-color: #ffffff;
    border: 1px solid var(--mainColor);
    flex: 1;
    position: relative;
}

.exclusive-list__wrapper:not(:first-child){
    display: none;

}
.exclusive-list__wrapper:not(:first-child).active{
    display: flex;
}

.exclusive-item.is-visible {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: 700px;
    background-color: #ffffff;
    border: 1px solid var(--mainColor);
    flex: 1;
    position: relative;
}

.exclusive-item:nth-child(3n) {
    top: -180px;
}

.exclusive-item:nth-child(2),
.exclusive-item:nth-child(3n+2) {
    top: 234px;
}

.exclusive-item-text-block {
    padding: 40px 30px;
}

.exclusive-item-title {
    color: #141414;
    font-size: 29px;
    margin-bottom: 40px;
}

.exclusive-item-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 27px 50px;
}

.exclusive-item-grid p {
    color: #141414;
    font-size: 37px;
}

.exclusive-item-grid-more-width {
    width: 230px;
}

.exclusive-item-grid span {
    color: #141414;
    font-size: 17px;
}

.exclusive-item-image {
    margin-top: auto;
    display: flex;
    align-items: end;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.exclusive-item-button {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--mainColor2);
    text-transform: uppercase;
    font-size: 12px;
    color: #ffffff;
    letter-spacing: 1px;
    font-weight: 600;
    cursor: pointer;
}

.exclusive__button {
    float: right;
    padding: 32px 38px;
    margin-top: 147px;
}

.exclusive-special-span {
    display: block;
}

.exclusive-special-span-top {
    margin-top: 10px;
}

.exclusive-item-top {
    display: none;
}

.exclusive-item-image-mobile {
    display: none;
}

.exclusive-item-image-mobile-new {
    margin-top: auto;
    display: flex;
    align-items: end;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.exclusive__slider__buttons {
    display: none;
}
@media (max-width: 1700px) {
    .exclusive-title span {
        right: -58%;
    }
    .exclusive-list__wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        margin-bottom: 30px;
    }
}

@media(max-width:1250px) {
    .exclusive-title {
        left: -200px;
    }
    .exclusive-title span {
        right: -54%;
    }
    .exclusive__button {
        width: 28%;
    }
}

@media (max-width: 1180px) {

    .exclusive-title span {
        right: -40%;
    }
    .exclusive-list__wrapper{
        display: flex !important;
        flex-direction: column-reverse;
        align-items: center;
        gap: 20px;
    }

    .exclusive .container {
        padding-bottom: 100px;
    }

    .exclusive-body {
        display: flex;
        flex-direction: column;
    }

    .exclusive-title {
        text-align: unset;
        margin-left: 80px;
        position: relative;
        left: 0;
    }

    .exclusive-item:nth-child(3n) .exclusive-item-title {
        padding-top: 85px;
    }

    .exclusive-list {
        display: block;
    }

    .exclusive-item {
        width: 100%;
    }

    .exclusive-item:nth-child(3n),
    .exclusive-item:nth-child(2),
    .exclusive-item:nth-child(3n+2){
        top: 0;
    }

    .exclusive-item-top {
        display: block;
        margin: -40px -30px;
        height: 430px;
    }

    .exclusive-item-image {
        display: none;
    }

    .exclusive-item-image-mobile {
        display: flex;
        flex-direction: column;
    }

    .exclusive-item:nth-child(3n) .exclusive-item-image-mobile-new {
        display: none;
    }

    .exclusive-item:nth-child(3n) .exclusive-item-button {
        margin-top: 0;
    }

    .exclusive-item-button {
        background-color: var(--mainColor);
        color: #141414;
        border-radius: 73px;
        margin-bottom: 20px;
        margin-top: -39px;
        width: 90%;
        align-self: center;
        border: none;
    }

    .exclusive__button {
        display: none;
    }
    .exclusive__slider__buttons {
        display: flex;
        margin-right: 100px;
        justify-content: end;
        margin-top: 50px;
    }
    .exclusive__slider__button {
        width: 70px;
        height: 70px;
        color: #123539;
        border: 1px solid #123539;
        background: none;
        border-radius: 99px;
        font-size: 30px;
        cursor: pointer;
    }
    .exclusive__slider__button:nth-child(2) {
        margin-left: 20px;
    }
}

@media (max-width: 850px) {
    .exclusive-title {
        margin-left: 40px;
    }

    .exclusive-body .exclusive-title {
        font-size: 55px;
    }

    .exclusive-body .exclusive-title span {
        font-size: 38px;
    }

    .exclusive-item {
        max-width: 500px;
    }

    .exclusive-item-top {
        height: 358px;
    }

    .exclusive-item-button {
        margin-top: -45px;
    }

    .exclusive__slider__buttons {
        margin-right: 80px;
    }
    .exclusive__slider__button {
        width: 50px;
        height: 50px;
        font-size: 25px;
    }
    .exclusive__slider__button:nth-child(2) {
        margin-left: 15px;
    }
}

@media (max-width: 600px) {
    .exclusive-title {
        margin-left: 23px;
    }

    .exclusive-title span {
        margin-top: -10px;
    }

    .exclusive-body .exclusive-title {
        font-size: 38px;
    }

    .exclusive-body .exclusive-title span {
        font-size: 30px;
        width: fit-content;
    }

    .exclusive-item {
        max-width: 500px;
    }

    .exclusive-item-image-mobile-new {
        height: 190px;
    }

    .exclusive-item-button {
        height: 52px;
        margin-top: -27px;
    }

    .exclusive-item-text-block {
        overflow: hidden;
    }

    .exclusive__slider__buttons {
        margin-right: 0;
        margin-top: 40px;
    }
    .exclusive__slider__button:nth-child(2) {
        margin-left: 10px;
    }

}

@media (max-width: 450px) {
    .exclusive .container {
        margin-top: 0px;
        padding-bottom: 0;
    }

    .exclusive-body .exclusive-title {
        font-size: 22px;
        margin-bottom: 20px;
    }

    .exclusive-body .exclusive-title span {
        font-size: 20px;
        right: -90px;
        margin-top: 0;
    }

    .exclusive-item {
        width: 80%;
    }

    .exclusive-item-image-mobile-new {
        height: 160px;
    }

    .exclusive-item-text-block {
        padding: 24px 18px;
    }

    .exclusive-item-title {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .exclusive-item-top {
        height: 245px;
    }

    .exclusive-special-span {
        font-size: 13px;
    }

    .exclusive-item-grid {
        gap: 15px 28px;
    }

    .exclusive-item-grid-more-width {
        width: 150px;
    }

    .exclusive-item-grid p {
        font-size: 21px;
    }

    .exclusive-item-grid p span {
        font-size: 13px;
    }

    .exclusive-item:nth-child(3) .exclusive-item-title {
        padding-top: 65px;
    }

    .exclusive-item-button {
        height: 40px;
        font-size: 9px;
        margin-top: -30px;
    }

    .exclusive__slider__buttons {
        margin-right: 30px;
        justify-content: end;
        margin-top: 15px;
        margin-bottom: 32px;
    }
    .exclusive__slider__button {
        width: 25px;
        height: 25px;
        color: #123539;
        border: 1px solid #123539;
        background: none;
        border-radius: 99px;
        font-size: 14px;
        cursor: pointer;
    }
    .exclusive__slider__button:nth-child(2) {
        margin-left: 5px;
    }
}

@media (max-width: 380px) {
    .exclusive-item-top {
        height: 210px;
    }

    .exclusive-item-grid-more-width {
        width: 130px;
    }

    .exclusive-item-image-mobile-new {
        height: 160px;
    }

    .exclusive-item-grid {
        gap: 15px 10px;
    }
}