@use '../../components/styles/mixins.scss' as *;

.button {
    //border: none;
    //background: transparent;
    //border-radius: 666px;
    //font-family: 'Montserrat', sans-serif;
    //font-size: 20px;
    //line-height: 20px;
    //text-align: center;
    //
    //@include tablet {
    //    font-size: 16px;
    //    line-height: 16px;
    //}
    //
    //@include mobile {
    //    font-size: 13px;
    //    line-height: 13px;
    //}

    &__accent {
        color: #273E42;
        background-color: #E0BE74;
    }

    &__primary {
        color: #FFFFFF;
        background-color: #152E33;
    }
}