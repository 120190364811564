.loader {
  display: block;
  margin: 0 auto;
  padding-bottom: 5px;
  width: 32px;
  height: 32px;
  border: 4px solid #FFF;
  border-bottom-color: #E0BE74;
  border-radius: 50%;

  animation: rotation 1s linear infinite;
  @media (max-width: 996px){
    width: 26px;
    height: 26px;
  }
  @media (max-width: 768px){
    width: 21px;
    height: 21px;
  }
  @media (max-width: 520px){
    width: 16px;
    height: 16px;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}