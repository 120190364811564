.process {
    padding: 140px 0 57px;
}

.process-body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.process-body-header-button {
    text-align: center;
    padding: 30px 70px;
}

.process-title p {
    margin: 0 0 0 42px;
}

.process-wrapper {
    display: flex;
    gap: 50px;
}

.process-list {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.process-list p {
    display: flex;
    align-items: center;
    font-size: 27px;
    color: #141414;
    border-bottom: 1px solid #CBCBCB;
    padding: 20px 0;
}

.process-list span {
    font-size: 55px;
    color: #152E33;
    width: auto;
    padding-right: 40px;
}

.process-image {
    width: 50%;
    max-width: 800px;
}

.process-image img {
    max-width: 100%;
}

.process-footer {
    display: none;
}

.process-popap {
    background-image: url("/public/img/presentation.png");
    max-width: 747px;
    background-repeat: no-repeat;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    display: none;
}

.process-popap.active {
    display: block;
}

.process-popap__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFFFFF;
    font-size: 43px;
    line-height: 61px;
    font-weight: 500;
    margin-top: 160px;
    margin-bottom: 61px;
}

.process-popap__header p {
    text-align: center;
    width: 80%;
    font-size: 28px;
    line-height: 37px;
    font-weight: 400;
    margin-top: 15px;
}

.process__form {
    margin-left: 72px;
}

.process__form__inputs {
    display: flex;
    flex-direction: column;
    gap: 55px;
    margin-bottom: 29px;
}

.process__form__input input {
    border: none;
    background: none;
    border-bottom: 1px solid #FFFFFF;
    color: #577980;
    padding-bottom: 18px;
    padding-left: 14px;
    font-size: 15px;
    line-height: 29px;
    width: 85%;
    cursor: pointer;
}

.process-popap__formError{
    margin-bottom: 15px;
    display: none;
    float: none;
    justify-content: center;
    width: 85%;
}

.process__form button {
    background-color: var(--mainColor);
    color: #141414;
    font-size: 14px;
    font-weight: 500;
    border-radius: 666px;
    cursor: pointer;
    height: 89px;
    width: 85%;
    margin-bottom: 27px;
    margin-top: 15px;
}

.process__form__info {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    width: 70%;
    margin-bottom: 15px;
}

.process__form-politic {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

.process__form-politic span {
    display: flex;
    gap: 10px;
    margin-bottom: 117px;
}

.process__form-politic span::before {
    content: '';
    display: block;
    width: 17px;
    height: 17px;
    border: 1px solid #CBCBCB;
    border-radius: 3px;
}

.process__form-politic input:checked+span::before {
    background-image: url("/public/img/check-white.png");
    background-position: center;
    background-repeat: no-repeat;
}

@media (max-width:1240px) {
    .process-body-header-button {
        width: 30%;
    }

    .process-popap__header {
        font-size: 35px;
        margin-bottom: 35px;
        margin-top: 150px;
    }

    .process-popap__header p {
        font-size: 22px;
    }

    .process__form {
        margin-left: 50px;
    }

    .process__form__inputs {
        gap: 20px;
        margin-bottom: 30px;
    }

    .process__form__input input {
        padding-bottom: 12px;
        font-size: 12px;
    }

    .process__form button {
        font-size: 12px;
        height: 65px;
        margin-bottom: 20px;
    }

    .process__form__info {
        font-size: 12px;
        width: 80%;
    }

    .process__form-politic {
        font-size: 12px;
    }

    .process__form-politic span {
        margin-bottom: 40px;
        margin-right: 20px;
    }
}


@media (max-width:1200px) {
    .process-wrapper {
        flex-direction: column;
    }

    .process-list {
        width: 95%;
    }

    .process-image {
        width: 95%;
        align-self: center;
    }

    .process-body-header-button {
        width: 40%;
    }
}

@media (max-width:995px) {
    .process-popap__header {
        font-size: 30px;
        line-height: 45px;
    }

    .process-popap__header p {
        font-size: 18px;
        margin-top: 15px;
        line-height: normal;
    }
}

@media (max-width:767px) {
    .process {
        padding: 80px 0 57px;
    }

    .process-body-header .process-title {
        font-size: 55px;
    }

    .process-body-header .process-title span {
        font-size: 38px;
    }

    .process-body-header-button {
        font-size: 12px;
        width: 55%;
    }

    .process-list p {
        font-size: 21px;
    }

    .process-list span {
        font-size: 36px;
    }

    .process-popap {
        width: 70%;
    }

    .process-popap__header {
        font-size: 25px;
        margin-bottom: 20px;
        line-height: 30px;
    }

    .process-popap__header p {
        font-size: 15px;
    }

    .process__form__inputs {
        gap: 15px;
        margin-bottom: 25px;
    }

    .process__form__input input {
        padding-bottom: 8px;
        font-size: 11px;
    }

    .process-popap__formError {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .process__form button {
        font-size: 10px;
        height: 55px;
        margin-bottom: 15px;
    }

    .process__form__info {
        font-size: 10px;
        width: 90%;
    }

    .process__form-politic {
        font-size: 10px;
    }

    .process__form-politic span {
        margin-bottom: 30px;
    }
}

@media (max-width:640px) {
    .process-wrapper {
        gap: 60px;
    }

    .process-footer {
        display: flex;
        flex-direction: column;
    }

    .process-image,
    .process-body-header-button {
        display: none;
    }

    .process-footer .button {
        width: 80%;
        align-self: center;
        text-align: center;
        height: 57px;
        padding-top: 19px;
        position: relative;
        top: -30px;
    }

    .process__form-politic span::before {
        border-radius: 20px;
    }

    .process__form-politic input:checked+span::before {
        background-image: url("/public/img/svg/check-yellow.svg");
        background-position: center;
        background-repeat: no-repeat;
    }
}

@media (max-width:450px) {
    .process {
        padding: 43px 0 50px;
    }

    .process-body-header .process-title {
        font-size: 35px;
        margin-bottom: 29px;
    }

    .process-body-header .process-title span {
        font-size: 25px;
    }

    .process-list p {
        font-size: 14px;
    }

    .process-list span {
        font-size: 22px;
        width: auto;
        padding-right: 20px;
    }

    .process-footer .button {
        font-size: 9px;
        font-weight: 600;
    }


    .process-popap {
        width: 90%;
    }

    .process-popap__header {
        font-size: 23px;
    }

    .process-popap__header p {
        font-size: 13px;
    }

    .process__form {
        margin-left: 30px;
    }

    .process__form__inputs {
        gap: 10px;
        margin-bottom: 20px;
    }

    .process__form__input input {
        padding-bottom: 2px;
        padding-left: 10px;
        font-size: 9px;
    }

    .process-popap__formError {
        margin-bottom: 0;
        font-size: 12px;
    }

    .process__form button {
        font-size: 8px;
        height: 48px;
    }

    .process__form__info {
        display: none;
    }

    .process__form-politic {
        font-size: 9px;
        line-height: 15px;
    }

    .process__form-politic span {
        margin-bottom: 20px;
    }

}