.slider-wrapper {
  position: relative;
}

.slick-slide {
  &__front{
  position: relative;
  }

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
}

.gallery-arrow {
  position: absolute;
  width: 52px;
  height: 52px;
  color: var(--mainColor);
  border: 1px solid #E0BE74;
  border-radius: 99px;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  background: #FFF;
  color: #E0BE74;
  bottom: 35px;
  right: 35px;
  z-index: 2;
  @media (max-width: 1024px){
    bottom: 25px;
    right: 25px;
    width: 45px;
    font-size: 14px;
    height: 45px;
  }
  @media (max-width: 768px){
    right: 20px;
    bottom: 20px;
    width: 35px;
    font-size: 12px;
    height: 35px;
  }
  @media (max-width: 520px){
    right: 13px;
    bottom: 13px;
    width: 26px;
    font-size: 10px;
    height: 26px;
  }
}

.gallery-prev {
  right: 95px;
  @media (max-width: 1024px){
    right: 85px;
  }
  @media (max-width: 768px){
    right: 60px;
  }
  @media (max-width: 520px){
    right: 46px;
  }
}

.gallery-next {
  right: 35px;
  @media (max-width: 1024px){
    right: 25px;
  }
  @media (max-width: 768px){
    right: 20px;
  }
  @media (max-width: 520px){
    right: 13px;
  }
}


.thumbnail-slider{
  @media (max-width: 520px){
    margin-bottom: 20px;
  }

  .slick-slide__thumbnail{
    width: 156px;
    height: 142px;
    padding: 0 12.5px;
    transition: .3s;
    cursor: pointer;

    &:hover{
      opacity: .8;
    }
    @media (max-width: 1380px){
      height: 120px;
    }
    @media (max-width: 1240px){
      height: 100px;
    }
    @media (max-width: 1024px){
      padding: 0 10px;
      height: 85px;
    }
    @media (max-width: 768px){
      padding: 0 6px;
      height: 60px;
    }
    @media (max-width: 520px){
      padding: 0 3px;
      width: 42px;
      height: 38px;
    }
  }
  .slick-list {
    margin: 0 -12.5px;
    @media (max-width: 1024px){
      margin: 0 -10px;
    }
    @media (max-width: 768px){
      margin: 0 -6px;
    }
    @media (max-width: 520px){
      margin: 0 -3px;
    }
  }
}

.slider-front{
  margin-bottom: 24px;
  
  @media (max-width: 1024px){
    margin-bottom: 15px;
  }
  @media (max-width: 520px){
    margin-bottom: 9px;
  }
  .slick-slide{
    height: 715px;
    @media (max-width: 1380px){
      height: 660px;
    }
    @media (max-width: 1240px){
      height: 620px;
    }
    @media (max-width: 1024px){
      height: 520px;
    }
    @media (max-width: 768px){
      height: 440px;
    }
    @media (max-width: 520px){
      height: 285px;
    }

  }

}
