@use '../../components/styles/mixins.scss' as *;

.input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border: none;
    font-size: 17px;
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 21px;
    padding-left: 19px;

    &:focus {
        outline: 0;
        outline-offset: 0;
    }

    @include tablet {
        font-size: 15px;
        padding-bottom: 15px;
        padding-left: 12px;
    }

    @include mobile {
        font-size: 13px;
    }

    &::placeholder {
        color: #C2C2C2;
    }
}