.single-wrapper {
  display: flex;
  padding: 110px 0 0 65px;
  margin-bottom: 100px;
  @media (max-width: 1240px) {
    padding: 90px 0 0 55px;
  }
  @media (max-width: 1100px){
    padding: 70px 0 0 50px;
  }
  @media (max-width: 996px) {
    padding: 40px 0 0 40px;
    margin-bottom: 65px;
  }
  @media (max-width: 768px) {
    padding: 35px 0 0 35px;
    margin-bottom: 55px;
    gap: 15px;
  }
  @media (max-width: 520px) {
    padding: 25px 0 0 25px;
    margin-bottom: 40px;
    gap: 10px;
  }
}