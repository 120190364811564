@use '../../components/styles/mixins.scss' as *;

.form_object {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 128px;
    z-index: 9;

    @include tablet {
        gap: 40px 60px;
    }

    @include mobile {
        grid-template-columns: 1fr;
        gap: 25px;
    }
}

.form-round {
    gap: 40px 90px;
}


.form__field {
    display: grid;
    outline: none;
    grid-column: 1/2;
    width: 464px;

    @media(max-width: 1700px) {
        width: auto;
    }
}

.form__input-phone {
    input {
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        border: none;
        font-size: 17px;
        border-bottom: 1px solid #D0D0D0;
        padding-bottom: 21px;
        padding-left: 19px;

        &:focus {
            outline: 0;
            outline-offset: 0;
        }

        @include tablet {
            font-size: 15px;
            padding-bottom: 15px;
            padding-left: 12px;
        }

        @include mobile {
            font-size: 13px;
        }

        &::placeholder {
            color: #C2C2C2;
        }
    }
}

.form__right-position {
    display: flex;
    flex-direction: column;
    grid-column: 2/3;
    grid-row: 1/4;
    align-self: center;
    gap: 25px;

    @include mobile {
        grid-column: auto;
        grid-row: auto;

        .custom-checkbox{
            margin: 0;
            .custom-checkbox__label{
                max-width: 100%;
            }
        }
    }

}

.form-round>.form__right-position {
    grid-row: 1/3;

    @include mobile {
        grid-row: 3/4;
    }
}


.button__accent {
    padding: 30px 96px;
    text-transform: none;
    color: #fff;
    @include tablet {
        padding: 30px 80px;
    }

    @include mobile {
        padding: 17px 112px;
        color: #141414;
    }
}

.form__error {
    color: red;
    align-self: center;
    border: 1px solid #E0BE74;
    border-radius: 99px;
    padding: 10px 20px;
    background-color: #E0BE74;
}