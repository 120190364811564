.card-banner {
  position: relative;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 100px;
  @media (max-width: 996px) {
    margin-bottom: 60px;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  &::after {
    content: '';
    background: url("../../../../../public/img/single/decor.png") 0 0 / cover no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
    @media (max-width: 520px) {
      background: url("../../../../../public/img/single/decor-mob.png") 0 0 / cover no-repeat;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding: 0 7px 128px;

    position: relative;
    z-index: 2;

    @media (max-width: 1060px) {
      align-items: center;
    }
    @media (max-width: 996px) {
      padding: 0 7px 80px;
    }
    @media (max-width: 768px) {
      align-items: center;
      padding: 0 7px 60px;
    }
    @media (max-width: 520px) {
      padding: 0 7px 42px;
    }
  }

  &__title {
    color: #FFF;
    font-size: 44px;
    font-weight: 400;
    margin-bottom: 113px;
    width: 100%;
    @media (max-width: 996px) {
      margin-bottom: 80px;
      font-size: 36px;
      text-align: center;
    }
    @media (max-width: 768px) {
      margin-bottom: 60px;
      font-size: 32px;
    }
    @media (max-width: 520px) {
      margin-bottom: 28px;
      font-size: 24px;
    }
  }
  &__skeleton{
    width: 220px !important;
    height: 100% !important;
    @media (max-width: 1050px){
      width: 194px !important;
      height: 66px !important;
    }
    @media (max-width: 768px){
      width: 120px !important;
      height: 40px !important;
    }
    @media (max-width: 520px){
      width: 100px !important;
      height: 33px !important;
    }
  }
  &__body {
    display: flex;
    gap: 47px;
    flex-wrap: wrap;
    @media (max-width: 1060px) {
      flex-direction: column-reverse;
    }
    @media (max-width: 520px) {
      gap: 32px;
    }
  }

  &__btns {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        text-transform: uppercase;
        font-weight: 600;
      }
      @media (max-width: 583px) {
        width: 100%;
        justify-content: center;
      }
      &:first-child {
        padding: 30px 36px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          padding: 25px 36px;
          font-size: 14px;
        }

        @media (max-width: 520px) {
          padding: 18px 36px;
          font-size: 9px;
        }
      }
    }
  }

  &__phone {
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    border-radius: 79px;
    border: 1px solid #FFF;
    padding: 26px 62px;
    @media (max-width: 768px) {
      padding: 18px 62px;
      font-size: 14px;
      letter-spacing: 0.9px;
      font-weight: 600;
    }
    @media (max-width: 520px) {
      font-size: 9px;
    }
  }

  &__more {
    display: flex;
    gap: 53px;
    @media (max-width: 1060px) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 768px) {
    gap: 30px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 4px;
    color: #FFF;
    font-size: 28px;
    font-weight: 400;
    @media (max-width: 996px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
    }
    @media (max-width: 520px) {
      font-size: 13px;
    }

    &:not(:last-child) {
      &::after{
        content: '';
        position: absolute;
        right: -25px;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #fff;
        height: 100%;
        @media (max-width: 768px) {
          right: -15px;
        }
      }

    }

  }

  &__text {
    color: #E4E4E4;
    font-weight: 300;
  }

}