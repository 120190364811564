.slider-button {
    & button {
        background-color: transparent;
        border: 1px solid #273E42;
        border-radius: 99px;
        cursor: pointer;
        @media (max-width:831px) {
            width: 50px;
            height: 50px;
        }

        @media (max-width: 450px) {
            width: 25px;
            height: 25px;
        }
    }
}