.scheme {
  margin-bottom: 160px;
  @media (max-width: 996px) {
    margin-bottom: 100px;
  }
  @media (max-width: 768px) {
    margin-bottom: 75px;
  }
  @media (max-width: 520px) {
    margin-bottom: 48px;
  }

  &__floors {
    display: flex;
    gap: 30px;
    margin-bottom: 95px;
    @media (max-width: 996px) {
      margin-bottom: 55px;
    }
    @media (max-width: 768px) {
      gap: 20px;
      margin-bottom: 40px;
    }
    @media (max-width: 520px) {
      gap: 15px;
      margin-bottom: 25px;
    }
  }

  &__floor-name {
    color: #000;
    font-size: 38px;
    font-weight: 300;
    line-height: 100%;
    @media (max-width: 1240px) {
      font-size: 32px;
    }
    @media (max-width: 996px) {
      font-size: 28px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
    }
    @media (max-width: 520px) {
      font-size: 20px;
    }
  }

  &__floor-list {

    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 27px;
    align-items: center;
    @media (max-width: 520px) {
      gap: 23px;
    }

    li {
      cursor: pointer;
      color: #E0BE74;
      font-size: 38px;
      font-weight: 300;
      line-height: 100%;
      border-bottom: 1px solid #E0BE74;
      @media (max-width: 996px) {
        font-size: 28px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
      @media (max-width: 520px) {
        font-size: 20px;
      }

      &.active {
        color: #123539;
        border-bottom: 1px solid #123539;
      }
    }
  }

  &__info {
    display: flex;
    gap: 30px;
    color: #000;
    font-size: 28px;
    font-weight: 300;
    line-height: 100%;
    margin-bottom: 40px;
    @media (max-width: 1024px) {
      font-size: 22px;
    }
    @media (max-width: 768px) {
      gap: 20px;
      font-size: 18px;
      margin-bottom: 30px;
    }
    @media (max-width: 520px) {
      gap: 15px;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }


  &__body {
    position: relative;
    display: flex;
    gap: 30px;
    overflow: auto;
    flex-direction: column;
    padding-bottom: 40px;
    @media (max-width: 996px) {
      max-height: 450px;
      gap: 25px;
    }
    @media (max-width: 768px) {
      max-height: 380px;
      gap: 20px;
    }
    @media (max-width: 520px) {
      max-height: 373px;
      gap: 14px;
    }

    &::-webkit-scrollbar {
      width: 14px;
      background-color: #D9D9D9;
      border-radius: 18px;
      @media (max-width: 996px) {
        width: 10px;
        border-radius: 12px;
      }
      @media (max-width: 768px) {
        width: 8px;
        border-radius: 10px;
      }
      @media (max-width: 520px) {
        width: 6px;
        height: 8px;
        border-radius: 8px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 18px;
      background-color: #152E33;
      @media (max-width: 996px) {
        border-radius: 12px;
      }
      @media (max-width: 768px) {
        border-radius: 10px;
      }
      @media (max-width: 520px) {

        border-radius: 8px;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 18px;
      background-color: #D9D9D9;
      @media (max-width: 996px) {
        border-radius: 12px;
      }
      @media (max-width: 768px) {
        border-radius: 10px;
      }
      @media (max-width: 520px) {
        border-radius: 8px;
        background: transparent;
      }
    }

    @media (max-width: 996px) {
      gap: 20px 16px;
    }
    @media (max-width: 768px) {
      gap: 15px 14px;
      padding-bottom: 30px;
    }
    @media (max-width: 520px) {
      gap: 16px;
      padding-bottom: 20px;
    }
  }

  &__meter {
    display: flex;
    gap: 21px;
    position: sticky;
    top: 0;
    z-index: 44;
    background: #F8F8F8;
    text-align: center;
    justify-content: center;
    height: 100%;
    width: max-content;
    @media (max-width: 996px){
      gap: 17px;
    }
    @media (max-width: 768px) {
      gap: 15px;
    }
    @media (max-width: 520px) {
      gap: 10px;
    }

    div {
      min-width: 152px;
      text-align: center;
      height: 42px;
      font-size: 28px;

      &:first-child {
        min-width: 37px;
        @media (max-width: 520px) {
          min-width: 32px;
        }
      }

      @media (max-width: 996px) {
        min-width: 105px;
        height: 35px;
        font-size: 18px;
      }
      @media (max-width: 768px) {
        min-width: 86px;
        height: 27px;
        font-size: 16px;
      }
      @media (max-width: 520px) {
        min-width: 72px;
        height: 20px;
        font-size: 14px;
      }
    }
  }

  &__options {
    display: flex;
    gap: 21px;
    flex-direction: column;
    width: max-content;

    div {
      display: flex;
      align-items: center;
      gap: 21px;

      @media (max-width: 1024px) {
        gap: 17px;
      }
      @media (max-width: 768px) {
        gap: 15px;
      }
      @media (max-width: 520px) {
        gap: 10px;
      }
    }

    @media (max-width: 768px) {
      gap: 15px;
    }
    @media (max-width: 520px) {
      gap: 10px;
    }
  }

  &__floor {
    min-width: 37px;
    position: sticky;
    left: 0;
    z-index: 2;
    background: #F8F8F8;
    border-radius: 50%;
    text-align: center;
    justify-content: center;
    height: 100%;
    padding: 8px;
    @media (max-width: 520px) {
      min-width: 32px;
    }
  }

  &__card {
    position: absolute;
    z-index: 200;

    & > article {
      position: relative;

      .apartment__price {
        font-size: 36px;
        @media (max-width: 996px) {
          font-size: 30px;
        }
        @media (max-width: 768px) {
          font-size: 26px;
        }
        @media (max-width: 520px) {
          font-size: 22px;
        }
      }

      .apartment__title {
        font-size: 18px;
        @media (max-width: 996px) {
          font-size: 15px;
        }
        @media (max-width: 768px) {
          font-size: 13px;
        }
        @media (max-width: 520px) {
          font-size: 11px;
        }
      }

      .apartment__favorites {
        width: 23px;
        height: 21px;
        left: 13px;
        top: 13px;
        @media (max-width: 996px) {
          left: 11px;
          top: 11px;
          width: 18px;
          height: 16px;
        }
        @media (max-width: 768px) {
          left: 10px;
          top: 10px;
          width: 16px;
          height: 14px;
        }
        @media (max-width: 520px) {
          left: 8px;
          top: 8px;
          width: 14px;
          height: 12px;
        }
      }

      .apartment__compare {
        right: 13px;
        top: 13px;
        width: 25px;
        height: 23px;
        @media (max-width: 996px) {
          right: 11px;
          top: 11px;
          width: 19px;
          height: 18px;
        }
        @media (max-width: 768px) {
          right: 10px;
          top: 10px;
          width: 17px;
          height: 16px;
        }
        @media (max-width: 520px) {
          right: 8px;
          top: 8px;
          width: 15px;
          height: 14px;
        }
      }

      &.apartment {
        padding: 47px 20px 13px;
        max-width: 356px;
        @media (max-width: 996px) {
          padding: 20px 18px 25px;
        }
        @media (max-width: 768px) {
          padding: 15px 17px 23px;
        }
        @media (max-width: 520px) {
          padding: 10px 12px 18px;
        }
      }

      .apartment__meter {
        font-size: 13px;
        @media (max-width: 996px) {
          font-size: 11px;
        }
        @media (max-width: 768px) {
          font-size: 10px;
        }
        @media (max-width: 520px) {
          font-size: 8px;
        }
      }

      .apartment__block-image {
        img {
          max-height: 196px;
          @media (max-width: 996px) {
            max-height: 165px;
          }
          @media (max-width: 768px) {
            max-height: 150px;
          }
          @media (max-width: 520px) {
            max-height: 133px;
          }
        }
      }

      .info-items__item {
        font-size: 14px;
        gap: 7px;

        @media (max-width: 996px) {
          font-size: 12px;
        }
        @media (max-width: 768px) {
          font-size: 10px;
          gap: 4px;
        }
        @media (max-width: 520px) {
          font-size: 8px;
        }
      }

      .apartment__more {
        background: #E0BE74;
        font-size: 8px;
        padding: 12.5px;
        @media (max-width: 768px) {
          font-size: 10px;
          padding: 11.5px;
        }
      }

      .apartment__select {
        font-size: 8px;
        border: 1px solid #E0BE74;
        padding: 12.5px;
        @media (max-width: 768px) {
          font-size: 10px;
          padding: 11.5px;
        }
      }

      .apartment__info {
        margin-bottom: 6px;
        @media (max-width: 520px) {
          margin-bottom: 3px;
        }
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 26px;
        width: 22px;
        height: 10px;
        background: url("../../../../../public/img/svg/arrCitation.svg");
      }
    }

  }

  &__price {
    min-width: 152px;
    border-radius: 37px;
    background: #E0BE74;
    width: max-content;
    height: 42px;

    &.active {
      display: flex;
      justify-content: center;
      border-radius: 37px;
      background: #152E33;
      color: #FFF;
      font-size: 22px;
      font-weight: 300;
      line-height: 100%;
      padding: 10px 15px;
      cursor: pointer;
      @media (max-width: 996px) {
        font-size: 16px;
        padding: 9px 12px;
      }
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 8px 10px;
      }
      @media (max-width: 520px) {
        font-size: 11px;
        padding: 5px 10px;
      }
    }

    @media (max-width: 996px) {
      min-width: 105px;
      padding: 8px 13px;
      height: 35px;
    }
    @media (max-width: 768px) {
      min-width: 86px;
      padding: 8px 12px;
      height: 27px;
    }
    @media (max-width: 520px) {
      min-width: 72px;
      padding: 5px 10px;
      height: 20px;
    }
  }
}