.specialist {
  border: 1px solid #E0BE74;
  background: #FFF;
  margin-top: auto;

  &__wrapper {
    position: relative;
    padding: 132px 33px 30px;
    @media (max-width: 996px) {
      padding: 100px 33px 30px 28px;
    }
    @media (max-width: 768px) {
      padding: 85px 33px 25px 28px;
    }
    @media (max-width: 520px) {
      padding: 78px 33px 16px 28px;
    }

    .header-button {
      padding: 22px 72px;
      margin: 0 auto 15px;
      display: block;
      width: max-content;
      @media (max-width: 768px) {
        padding: 17px 51px;
        margin: 0 auto 13px;
        color: #152E33 !important
      }
    }
  }

  &__img {
    position: absolute;
    top: -42px;
    left: 0;
    right: 0;

    margin: auto;
    width: 166px;
    height: 166px;
    @media (max-width: 996px){
      width: 120px;
      height: 120px;
    }
    @media (max-width: 768px) {
      width: 104px;
      height: 104px;
    }

    img {
      max-width: 100%;
      object-fit: cover;
      pointer-events: none;
      user-select: none;
    }
  }

  &__name {
    margin-bottom: 8px;
    color: #123539;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    line-height: 130%;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (max-width: 520px) {
      font-size: 14px;
    }
  }

  &__job {
    color: #123539;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
    @media (max-width: 520px) {
      font-size: 12px;
    }
  }

  &__phone {
    border-radius: 27px;
    padding: 15px 55px;
    border: 1px solid #E0BE74;
    outline: none;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    display: block;
    width: max-content;
    margin: 0 auto 10px;
    @media (max-width: 768px) {
      padding: 13px 30px;
      margin: 0 auto 8px;
    }
    &::placeholder{
      color: #C0C0C0;
    }
  }

  label {
    width: max-content;
    margin: 0 auto;
    padding-left: 35px;
    input {
      &:checked {
        & + span {
          &::before {
            content: '';
            background: url("../../../../../public/img/checked.svg") left 3px top 4px / cover no-repeat;
            background-size: 9px;
            position: absolute;
            left: -28px;
          }
        }
      }
    }

    span {
      color: #8A8A8A;
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;
      max-width: 203px;
      margin: 0 auto;

      position: relative;
      &::before{
        position: absolute;
        left: -28px;
        border: 1px solid #8A8A8A;
        width: 17px;
        height: 17px;
        content: '';
        border-radius: 55px;
      }
      @media (max-width: 520px) {
        max-width: 150px;
        font-size: 9px;
      }
    }
  }
}