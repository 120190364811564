html {
  scroll-behavior: smooth;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Затемнение */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
}

.overlay.active {
  visibility: visible;
  opacity: 1;
}

.plan {
  background-color: #F8F8F8;
  padding: 120px 0 0;
}

.plan-body {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 150px;
}

.plan-title {
  padding-left: 40px;
}

.plan-title span {
  margin-left: -40px;
}

.plan-body-text {
  width: 50%;
}

.plan-calc {
  width: 50%;
  height: 1024px;
  background-image: url("../public/img/calc-bg2.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 180px 80px 67px;
}

.plan-calc form {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.plan-calc-title {
  color: #141414;
  font-size: 35px;
  margin-bottom: 40px;
}

.plan-calc label span {
  font-size: 15px;
  font-weight: 500;
  color: #141414;
  margin-left: 11px;
}

.plan-calc label input {
  border: 1px solid #CBCBCB;
  background-color: #ffffff;
  color: #141414;
  font-size: 15px;
  padding: 13px 22px;
  width: 100%;
  margin-top: 10px;
}

.plan-calc label input::placeholder {
  color: #ACACAC;
}

.plan-calc button {
  width: 100%;
  padding: 30px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 50px;
}

.plan-calc-info-text.is-active {
  display: block;
}

.plan-calc-info-tab {
  display: flex;
  justify-content: space-between;
  align-items: bottom;
  gap: 24px;
  margin-bottom: 50px;
}

.plan-calc-info-tab p {
  width: 100%;
  border-bottom: 1px solid #CBCBCB;
  padding-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.plan-calc-info-tab p.is-active {
  border-bottom: 1px solid var(--mainColor);
}

.plan-calc-info-text {
  display: none;
  position: relative;
  padding-bottom: 100px;
}

.plan-calc-info-title {
  font-size: 35px;
  color: #141414;
  line-height: 42px;
  width: 60%;
}

.plan-calc-info-desc {
  height: 243px;
  font-size: 22px;
  line-height: 29px;
  color: #141414;
  margin: 22px 0;
}

.plan-calc-info-nums {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plan-calc-info-nums p {
  font-size: 22px;
  color: #999999;
}

.plan-calc-info-nums span {
  color: #152E33;
  font-size: 33px;
  display: block;
}

.plan-calc__button {
  margin-bottom: 30px;
}

.plan-calc__slider-container {
  width: 100%;
  position: relative;
}

.plan-calc__slider-container label {
  font-size: 15px;
  font-weight: 500;
  color: #141414;
  margin-left: 12px;
}

.plan-calc__slider-container input {
  margin-top: 32px;
}

.plan-calc__slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 3px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

.plan-calc__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 35px;
  background: url("../public/img/svg/calcKey.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  border: none;
}

.plan-calc__slider::-moz-range-thumb {
  width: 20px;
  height: 30px;
  background: url("../public/img/svg/calcKey.svg") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  border: none;
}

.plan-calc__slider-labels {
  position: relative;
  width: 100%;
  margin-top: 10px;
  height: 20px;
}

.plan-calc__slider-labels span {
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 14px;
}

.plan-calc__slider-labels span:nth-child(1) {
  left: 3%;
}

.plan-calc__slider-labels span:nth-child(2) {
  left: 18.66%;
}

.plan-calc__slider-labels span:nth-child(3) {
  left: 34.33%;
}

.plan-calc__slider-labels span:nth-child(4) {
  left: 50%;
}

.plan-calc__slider-labels span:nth-child(5) {
  left: 65.66%;
}

.plan-calc__slider-labels span:nth-child(6) {
  left: 81.33%;
}

.plan-calc__slider-labels span:nth-child(7) {
  left: 97%;
}

.plan-calc__modile-off {
  margin-top: 50px;
}

.plan-calc__modile-off p {
  margin-top: 9px;
}

@media(max-width: 1753px) {
  .plan-body {
    gap: 100px;
  }

  .plan-calc {
    width: 65%;
  }
}

@media(max-width: 1625px) {
  .plan-calc-info-title {
    width: 70%;
  }
}

@media(max-width: 1600px) {
  .plan-body {
    gap: 25px;
  }
}

@media(max-width: 1530px) {
  .plan-body {
    padding-bottom: 50px;
    flex-direction: column;
    align-items: center;
  }

  .plan-body-text {
    width: 80%;
  }

  .plan-calc-info-title {
    width: 70%;
  }

  .plan-calc-info-desc {
    height: 180px;
  }

  .plan-calc {
    width: 57%;
    padding: 180px 80px 15px;
    margin-top: 80px;
  }

  .plan-calc__modile-off {
    margin-top: 30px;
  }
}

@media(max-width: 1265px) {
  .plan-calc {
    padding: 100px 40px 15px;
  }

  .plan-calc-info-desc {
    height: 220px;
  }
}

@media(max-width: 1200px) {
  .plan-calc {
    height: 901px;
  }
}


@media(max-width: 1160px) {
  .plan-calc {
    width: 70%;
    background-image: none;
    border: 1px solid #E0BE74;
    height: auto;
    padding: 25px 15px 40px;
  }
}

@media(max-width: 935px) {
  .plan-body-text .plan-title {
    font-size: 50px;
  }

  .plan-body-text .plan-title span {
    font-size: 38px;
  }

  .plan-calc-info-title {
    font-size: 28px;
  }

  .plan-calc-info-desc {
    font-size: 18px;
  }

  .plan-calc-info-nums span {
    font-size: 25px;
  }

  .plan-calc-info-nums p {
    font-size: 19px;
  }
}

@media(max-width: 768px) {
  .plan-calc {
    margin-top: 50px;
  }

  .plan-calc-info-desc {
    height: 290px;
  }
}

@media(max-width: 640px) {
  .plan {
    padding: 50px 0 0;
  }

  .plan-body {
    gap: 50px;
    padding-bottom: 30px;
  }

  .plan-body-text .plan-title {
    margin-bottom: 25px
  }

  .plan-title {
    padding-left: 0;
  }

  .plan-title span {
    margin-left: 0;
  }

  .plan-body-text .plan-title span {
    margin-left: 85px;
  }

  .plan-calc {
    width: 90%;
  }

  .plan-calc-title {
    font-size: 25px;
  }

  .plan-calc-info-title {
    width: 90%;
  }

  .plan-calc-info-tab {
    margin-bottom: 30px;
  }

  .plan-calc-info-desc {
    margin: 9px 0 12px 0;
  }

  .plan-calc-info-nums span {
    font-size: 21px;
  }

  .plan-calc-info-nums p {
    font-size: 16px;
  }

  .plan-calc button {
    padding: 25px;
    font-size: 13px;
  }
}

@media(max-width: 550px) {
  .plan-body-text .plan-title span {
    margin-left: 45px;
  }

  .plan-calc-info-desc {
    height: 350px;
  }

  .plan-calc-info-nums span {
    font-size: 16px;
  }

  .plan-calc-info-nums p {
    font-size: 14px;
  }
}

@media(max-width: 500px) {
  .plan-body-text {
    width: 95%;
  }

  .plan-body-text .plan-title {
    font-size: 35px;
  }

  .plan-body-text .plan-title span {
    font-size: 25px;
    margin-left: 85px;
  }

  .plan-calc-info-tab p {
    font-size: 10px;
  }

  .plan-calc-info-title {
    font-size: 18px;
    line-height: 21px;
  }

  .plan-calc-info-desc {
    font-size: 14px;
    height: 280px;
  }

  .plan-calc {
    width: 100%;
    padding-bottom: 20px;
  }

  .plan-calc-title {
    font-size: 19px;
    margin-bottom: 20px;
  }

  .plan-calc form {
    gap: 10px;
  }

  .plan-calc label span {
    font-size: 11px;
  }

  .plan-calc__slider-container input {
    margin-top: 17px;
  }

  .plan-calc label input {
    font-size: 11px;
  }

  .plan-calc__slider-container label {
    font-size: 11px;
  }

  .plan-calc button {
    padding: 16px;
    font-size: 10px;
    margin-top: 28px;
  }

  .plan-calc__button {
    margin-bottom: 0;
  }

  .plan-calc__modile-off p {
    font-size: 9px;
  }
}

@media(max-width: 375px) {
  .plan-calc-info-desc {
    height: 350px;
  }
}

@media(max-width: 355px) {
  .plan-body-text .plan-title span {
    margin-left: 50px;
  }
}