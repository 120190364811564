.footer-top {
    height: 800px;
    background-image: url("/public/img/footer-top-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 150px;
    overflow: hidden;
}

.footer-title {
    color: #ffffff;
    font-size: 77px;
}

.footer-copy-text-hidden.visible {
    display: block;
    opacity: 1;
    transition: opacity 0.1s ease;
}

.footer-top__marquee {
    position: relative;
    margin-top: 70px;
}

.footer-top__runing-logo {
    height: 250px;
    display: inline-block;
    animation: marquee 10s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.footer-bottom {
    padding: 100px 0 90px 0;
    background-color: #141414;
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(4, auto);
}

.footer-logo {
    width: 235px;
    display: flex;
    flex-direction: column;
}

.footer-logo img {
    max-width: 228px;
    margin: 0 auto;
    margin-bottom: 25px;
}

.footer-logo p {
    margin-top: 90px;
    color: #ffffff;
    font-size: 14px;
}

.footer-logo .footer-logo-button {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 13px;
    font-weight: 500;
    text-align: center;
}

.footer-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-menu a {
    font-size: 15px;
    color: #ffffff;
}

.footer-social {
    max-width: 330px;
}

.footer-social-link {
    display: block;
    font-size: 14px;
    color: #ffffff;
    text-decoration: underline;
    margin-bottom: 10px;
}

.footer-flex {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 15px;
}

.footer-flex a,
.footer-info-block {
    border: 1px solid #ffffff;
    padding: 13px 17px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    color: #ffffff;
    font-size: 11px;
}

.footer-social .footer-flex a {
    height: 40px;
}

.footer-flex a:first-child {
    width: 160px;
}

.footer-flex a:last-child {
    width: 140px;
}

.footer-info-block {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 24px 20px;
}

.footer-info-block span {
    font-size: 15px;
    font-weight: 300;
}

.footer-info-block svg {
    margin-left: auto;
}

.footer-info-block span.footer-info-title {
    font-size: 15px;
    font-weight: 500;
    width: 100px;
}

div.footer-info-block {
    align-items: start;
}

.footer-info-block div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer-copy {
    background-color: #1D1D1D;
    padding: 20px 0;
}

.footer-copy-text-hidden {
    /* opacity: 0;
    height: 0px;
    visibility: hidden; */
    display: none;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.footer-copy p {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 15px;
    transition: all .2s;
}

.footer-copy span {
    color: var(--mainColor);
}

.footer-copy-button {
    border: 1px solid #ffffff;
    color: var(--mainColor);
    width: 110px;
    height: 27px;
    border-radius: 15px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 1440px) {
    .footer-title {
        font-size: 70px;
    }
}

@media (max-width: 1330px) {

    .footer-top__runing-logo {
        height: 220px;
    }

    .footer-flex {
        flex-direction: column;
    }

    .footer-flex a:last-child {
        width: 160px;
    }

    .footer-social-link {
        margin-top: 20px;
    }

    .footer-social {
        width: 200px;
    }

    .footer-info-block {
        width: 400px;
    }
}

@media (max-width: 1150px) {
    .footer-top__runing-logo {
        height: 200px;
    }

    .footer-bottom {
        padding: 70px 0 60px 0;
    }

    .footer-logo {
        width: 180px;
    }

    .footer-logo img {
        max-width: 180px;
    }

    .button {
        font-size: 11px;
    }

    .footer-logo p {
        margin-top: 50px;
    }

    .footer-info-block span.footer-info-title {
        width: 90px;
    }

    .footer-menu {
        width: 150px;
    }

    .footer-social {
        width: 160px;
    }

    .footer-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-logo p {
        text-align: center;
    }
}

@media (max-width: 970px) {
    .footer-top__marquee {
        margin-top: 120px;
    }

    .footer-title {
        font-size: 55px;
    }

    .footer-info-block {
        width: 300px;
    }
}

@media (max-width: 900px) {
    .footer-logo p {
        margin-top: 25px;
    }

    .footer-grid {
        grid-template-columns: initial;
        grid-template-areas:
            "b-logo b-social b-info"
            "b-menu b-social b-info";
    }

    .footer-logo {
        grid-area: b-logo;
        margin-bottom: 60px;
    }

    .footer-social {
        grid-area: b-social;
    }

    .footer-info {
        grid-area: b-info;
    }

    .footer-menu {
        grid-area: b-menu;
    }

    .footer-logo p {
        text-align: left;
    }
}

@media (max-width: 802px) {
    .footer-top__marquee {
        margin-top: 70px;
    }
}

@media (max-width: 730px) {
    .footer-top {
        display: none;
    }

    .footer-bottom {
        padding: 60px 30px 50px 30px;
    }

    .footer-grid {
        grid-template-areas:
            "b-menu b-logo"
            "b-info b-info"
            "b-social b-social";
    }

    .footer-info-block {
        width: 100%;
    }

    .footer-menu {
        width: auto;
    }

    .footer-info {
        margin: 50px 0;
    }

    .footer-logo {
        width: 100%;
    }

    .footer-logo p {
        text-align: center;
    }

    .footer-info-block span.footer-info-title {
        width: 120px;
    }

    .footer-social {
        width: 100%;
    }

    .footer-flex {
        flex-direction: row;
    }

    .footer-social-link {
        margin-bottom: 0;
    }

}

@media (max-width: 545px) {
    .footer-bottom {
        padding: 60px 0 30px 0;
    }
}

@media (max-width: 500px) {
    .footer-grid {
        grid-template-areas:
            "b-menu b-menu"
            "b-logo b-logo"
            "b-info b-info"
            "b-social b-social";
    }

    .footer-logo {
        margin-bottom: 0;
        margin-top: 40px;
    }

    .footer-info {
        margin: 35px 0;
    }

    .footer-info-block {
        border-radius: 30px;
        padding: 12px 20px;
    }

    .footer-flex a:last-child {
        width: 140px;
    }
}

@media (max-width: 467px) {
    .footer-info-block span.footer-info-title {
        width: 80px;
        font-size: 11px;
    }

    .footer-info-block span {
        font-size: 11px;
    }

    .footer-logo-button {
        height: 50px;
    }

    .footer-logo img {
        max-width: 100%;
    }

    .footer-logo p {
        font-size: 12px;
    }
}

@media (max-width: 390px) {
    .footer-info-block span.footer-info-title {
        width: 70px;
    }

    .footer-social-link {
        font-size: 11px;
    }

    .footer-grid {
        width: 100%;
    }
}

@media (max-width: 370px) {
    .footer-flex a:first-child {
        width: 115px;
    }

    .footer-flex a:last-child {
        width: 90px;
    }

    .footer-flex a {
        font-size: 8px;
        padding: 10px 7px;

    }

    .footer-flex {
        justify-content: initial;
    }

    .footer-copy p {
        font-size: 8px;
    }
}

@media (max-width: 350px) {
    .footer-menu a {
        font-size: 11px;
    }

    .footer-info-block span.footer-info-title {
        width: 60px;
    }

    .footer-copy-button {
        font-size: 8px;
    }

    .footer-flex svg {
        width: 11px;
        height: 9px;
    }

    .footer-flex a {
        padding: 6px 7px;
    }

    .footer-social .footer-flex a {
        height: 30px;
    }

    .footer-social-link:last-child {
        margin-top: 10px;
    }

    .footer-menu {
        gap: 12px;
    }

    .footer-logo img {
        max-width: 180px;
    }
}